import { Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DistributionUploadFileComponent } from '../shared/dialogs/distribution-upload-file/distribution-upload-file.component';
import { DistributionsService } from 'src/app/services/distributions.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/services/main.service';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DistributionUploadGuidesFileComponent } from '../shared/dialogs/distribution-upload-guides-file/distribution-upload-guides-file.component';
import { DistributionUploadRacksFileComponent } from '../shared/dialogs/distribution-upload-racks-file/distribution-upload-racks-file.component';
import { BoxMaterialsListComponent } from '../shared/dialogs/box-materials-list/box-materials-list.component';
import { BgMaterialsListComponent } from '../shared/dialogs/bg-materials-list/bg-materials-list.component';
import { RackMaterialsListComponent } from '../shared/dialogs/rack-materials-list/rack-materials-list.component';
import Swal from 'sweetalert2';
import { MatTabGroup } from '@angular/material/tabs';
import { MfAddComponent } from '../shared/dialogs/mf-add/mf-add.component';
import { MfDocComponent } from '../shared/dialogs/mf-doc/mf-doc.component';
import { CloseBoxesComponent } from '../shared/dialogs/close-boxes/close-boxes.component';
import { DistributionUploadShipmentsFileComponent } from '../shared/dialogs/distribution-upload-shipments-file/distribution-upload-shipments-file.component';
import { DistributionEditShipmentDataComponent } from '../shared/dialogs/distribution-edit-shipment-data/distribution-edit-shipment-data.component';
import { DistributionMaterialsUploadImgsComponent } from '../shared/dialogs/distribution-materials-upload-imgs/distribution-materials-upload-imgs.component';
import { DistributionMaterialsImgSlidersComponent } from '../shared/dialogs/distribution-materials-img-sliders/distribution-materials-img-sliders.component';
import { DistributionUploadOrderedGuidesComponent } from '../shared/dialogs/distribution-upload-ordered-guides/distribution-upload-ordered-guides.component';

@Component({
  selector: 'app-distribution',
  templateUrl: './distribution.component.html',
  styleUrls: ['./distribution.component.scss']
})
export class DistributionComponent {
  @ViewChild('tabGroup', {static: false}) tab!: MatTabGroup;  
  campana!: any;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  dsSucursales!: MatTableDataSource<any>;
  colsSucursales: string[] = ['box_id', 'id_ext', 'filial', 'zona', 'sucursal', 'consecutivo', 'status', 'actions'];

  @ViewChild("paginatorBG") paginatorBG!: MatPaginator;
  @ViewChild("sortBG") sortBG!: MatSort;
  dsBGs!: MatTableDataSource<any>;
  colsBGs: string[] = ['bag_id', 'BG', 'cantidad', 'cantidad_sku', 'cantidad_pza', 'available', 'actions'];

  @ViewChild("paginatorRK") paginatorRK!: MatPaginator;
  @ViewChild("sortRK") sortRK!: MatSort;
  dsRacks!: MatTableDataSource<any>;
  colsRacks: string[] = ['nombre', 'line', 'tipo', 'actions'];

  @ViewChild("paginatorMat") paginatorMat!: MatPaginator;
  @ViewChild("sortMat") sortMat!: MatSort;
  dsMaterials!: MatTableDataSource<any>;
  colsMat: string[] = ['sku', 'nombre', 'tipo', 'cantidad', 'status', 'actions'];

  @ViewChild("paginatorMf") paginatorMf!: MatPaginator;
  @ViewChild("sortMf") sortMf!: MatSort;
  dsMf!: MatTableDataSource<any>;
  colsMf: string[] = ['nombre', 'paqueteria', 'empresa', 'recolector', 'cantidad', 'fecha_salida', 'actions'];

  @ViewChild("paginatorSh") paginatorSh!: MatPaginator;
  @ViewChild("sortSh") sortSh!: MatSort;
  dsSh!: MatTableDataSource<any>;
  colsSh: string[] = ['actions', 'status', 'id', 'consecutivo', 'address','reference', 'provider', 'carrier', 'service', 'weight', 'length', 'width', 'height'];
  guias_compradas: number = 0;
  total_guias: number = 0;
  error_guias: number = 0;
  total_locales: number = 0;
  datos_envio: any = [];
  camp_id: number = 0;
  isLoadingShipments = false;
  progress: number = 0;
  deteniendo_compra : boolean = false;
  procesando: any = [];

  constructor(
    private distService : DistributionsService,
    private campaignS: CampaignsService,
    private main : MainService,
    private _params: ActivatedRoute,
    private _router : Router,
    private matDialog: MatDialog
  ) { 
    this._params.params.subscribe(params => {
      if(params["id"]){
        this.loadCampaignData(params["id"]);
        this.camp_id = params["id"];
      }else{
        this._router.navigateByUrl('/admin/dashboard');
      } 
    });
  }
   ngOnInit(): void {
  }
  
  applyFilter(event: Event, ds: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    ds.filter = filterValue.trim().toLowerCase();
    if (ds.paginator) ds.paginator.firstPage();
  }

  loadCampaignData(camp_id: any){
    this.campaignS.getCampaign(camp_id).subscribe(
      (result : any) => {
        if(result.data) {
          this.campana = result.data[0];
          this.loadDistData(this.campana.id);
        }
      },
      (err : any) => {
        console.log(err);
      }
    );
  }
  
  loadDistData(camp_id: any){
    this.distService.getSucBgs(camp_id).subscribe(
      (result : any) => {
        if(result.data) {
          if(result.data.sucursales) {
            this.dsSucursales = new MatTableDataSource(result.data.sucursales);
            this.dsSucursales.paginator = this.paginator;
            this.dsSucursales.sort = this.sort;

            result.data.bg.map((e:any)=> { 
              const status_txt = e.status==1?"En existencia":(e.status==2?"Faltante":"Retirar de linea");
              return Object.assign(e, {status_txt});
            })
            this.dsBGs = new MatTableDataSource(result.data.bg);
            this.dsBGs.paginator = this.paginatorBG;
            this.dsBGs.sort = this.sortBG;

            this.dsRacks = new MatTableDataSource(result.data.racks);
            this.dsRacks.paginator = this.paginatorRK;
            this.dsRacks.sort = this.sortRK;
            
            result.data.materiales.map((e:any)=> { 
              const status_txt = e.status==1?"En existencia":(e.status==2?"Faltante":"Retirar de linea");
              return Object.assign(e, {status_txt});
            })
            this.dsMaterials = new MatTableDataSource(result.data.materiales);
            this.dsMaterials.paginator = this.paginatorMat;
            this.dsMaterials.sort = this.sortMat;
          }
        }
      },
      (err : any) => {
        console.log(err);
      }
    );
  }

  onTabChanged(ev: any) {
    let selected_tab = ev.index;
    switch (selected_tab) {
      case 4:
        if(!this.dsMf) this.loadListMf(); 
        break;
      case 5: 
        if(!this.dsSh) this.loadListSh(); 
        break
      ;
    }
    
  }

  loadListMf(){
    this.distService.getMf(this.campana.id).subscribe(
      (result : any) => {
        if(result.data) {
          this.dsMf = new MatTableDataSource(result.data);
          this.dsMf.paginator = this.paginatorMf;
          this.dsMf.sort = this.sortMf;
        }
      },
      (err : any) => {
        console.log(err);
      }
    );
  }
  
  loadListSh(){
    this.distService.getDisShipmentData(this.campana.id).subscribe(
      (result : any) => {
        if(result.data) {
          this.datos_envio = result.data;
          this.guias_compradas = result.data.filter((e:any)=>e.status=="success").length;
          this.error_guias = result.data.filter((e:any)=>e.status=="error").length;
          this.total_locales = result.data.filter((e:any)=>e.carriers.toLowerCase()=="local").length;
          this.total_guias = result.data.length;
          this.progress = Math.floor((this.guias_compradas * 100) / (this.total_guias - this.total_locales));
          this.dsSh = new MatTableDataSource(this.datos_envio);
          this.dsSh.sort = this.sortSh;
          this.dsSh.paginator = this.paginatorSh;
        }
      },
      (err : any) => {
        console.log(err);
      }
    );
  }

  
  stopGetLabels() {
    this.deteniendo_compra = true;
    Swal.fire({
      icon: 'info',
      title: 'Deteniendo proceso...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
  }

  loadGetLabels() {
    let arr_ids = this.datos_envio.filter((e:any)=>e.status != "success"&&e.status != "in_progress"&&e.carriers.toLowerCase()!="local").map((e:any)=>e.id);
    let resultado = [];

    for (var i = 0; i < arr_ids.length; i += 50) {
        resultado.push(arr_ids.slice(i, i + 50));
    }
    const res_gl = this.getLabels(resultado, 0);
  }
  
  getLabels(arr: any, i: number) {
    this.procesando = arr[i];
    this.isLoadingShipments = true;
    this.distService.getLabels(this.camp_id, arr[i]).subscribe(
      (result : any) => {
        if(result.data) {
          this.datos_envio = this.datos_envio.map((e:any)=> {
            const obj_res = result.data.find((d:any)=> d.id==e.id);
            return Object.assign(e, { status: e.status!="success"?obj_res?.status:e.status, label: e.status!="success"?obj_res?.label:e.label });
          });

          this.guias_compradas = this.datos_envio.filter((e:any)=>e.status=="success").length;
          this.error_guias = result.data.filter((e:any)=>e.status=="error").length;
        }

        if(!this.deteniendo_compra) {
          if((i+1) < arr.length) {
            this.progress = Math.floor((this.guias_compradas * 100) / (this.total_guias - this.total_locales));
            this.getLabels(arr, (i+1));
          }
          else {
            this.progress = 100;
            Swal.fire({
              icon: 'success',
              title: 'Proceso finalizado',
              text: 'Proceso de compra finalizado!'          
            });
            this.isLoadingShipments = false;
          }
        }
        else {
          this.loadListSh();
          Swal.fire({
            icon: 'success',
            title: 'Proceso detenido',
            text: 'Proceso de compra detenido por el usuario.'
          });
          this.isLoadingShipments = false;
        }
        this.procesando = [];
      },
      (err : any) => {
        console.log(err);
        Swal.fire({
          icon: 'success',
          title: 'Proceso detenido',
          text: 'Proceso de compra detenido por el sistema.'
        });
        this.isLoadingShipments = false;
      }
    );
  }

  changeMatStatus(row: any, status: number, type: string){
    const camp_id = this.campana.id;
    const id = row.id;
    Swal.fire({
      title: '¿Estás seguro que deseas modificar el estatus del material?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed && ["mat", "bg"].includes(type)) {
        this.distService.changeMatStatus(camp_id, id, status, type).subscribe(
          (result : any) => {
            if(result.message == "Success") {
              Swal.fire({
                icon: 'success',
                title: 'Guardado',
                text: 'Estatus actualizado de manera correcta!'          
              });
              this.loadDistData(this.campana.id);
              if(type == "mat") this.tab.selectedIndex = 3;
              if(type == "bg") this.tab.selectedIndex = 1;
            }
          },
          (err : any) => {
            console.log(err);
            Swal.fire({
              icon: 'error',
              title: 'Error al guardar',
              text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
            });
          }
        );
      }
    });
  }

  
  generateShipmentsFile(){
    const camp_id = this.campana.id;
    Swal.fire({
      title: '¿Estás seguro que deseas generar el archivo de guias?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        Swal.fire({
          icon: 'info',
          title: 'Generando archivo...',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
        this.distService.generateShipmentsFile(camp_id).subscribe(
          (result : any) => {
            if(result.message == "Success") {
              window.open(result.data, "_blank");
              Swal.fire({
                icon: 'success',
                title: 'Archivo creado',
                text: 'Archivo generado de manera correcta!'
              });
            }
          },
          (err : any) => {
            console.log(err);
            Swal.fire({
              icon: 'error',
              title: 'Error al generar',
              text: 'Ocurrió un error al generar el archivo de guias, por favor vuelve a intentarlo.'
            });
          }
        );
      }
    });
  }

  downloadFileDistData(){
    this.distService.getFileSucBgs(this.campana.id).subscribe(
      (result : any) => {
        if(result.data) {
          window.open(result.data, "_blank");
        }
      },
      (err : any) => {
        console.log(err);
      }
    );
  }

  openUploadDist(){
    this.matDialog.open(DistributionUploadFileComponent, {
      panelClass: ['dialogs-lg'],
      data: {
        campaign : this.campana
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.reload) this.loadCampaignData(this.campana.id);
      }
    );
  }

  openUploadRackDist(){
    this.matDialog.open(DistributionUploadRacksFileComponent, {
      panelClass: ['dialogs-lg'],
      data: {
        campaign : this.campana
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.reload) this.loadCampaignData(this.campana.id);
      }
    );
  }

  openUploadGuides(){
    this.matDialog.open(DistributionUploadGuidesFileComponent, {
      panelClass: ['dialogs-sm'],
      data: {
        campaign : this.campana
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.data) window.open(resp.data, "_blank");
      }
    );
  }

  openUploadOrderedGuides(){
    this.matDialog.open(DistributionUploadOrderedGuidesComponent, {
      panelClass: ['dialogs-sm'],
      data: {
        campaign : this.campana
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.data) window.open(resp.data, "_blank");
      }
    );
  }

  openUploadMaterialImgs(){
    this.matDialog.open(DistributionMaterialsUploadImgsComponent, {
      panelClass: ['dialogs-sm'],
      data: {
        campaign : this.campana
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.reload) {
          this.loadCampaignData(this.campana.id);
          this.tab.selectedIndex = 3;
        }
      }
    );
  }

  openDialogMaterialList(row:any) {
    this.matDialog.open(BoxMaterialsListComponent, {
      panelClass: ['dialogs-main'],
      data: {
        box : row
      }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }

  openDialogBGMaterialList(row:any) {
    this.matDialog.open(BgMaterialsListComponent, {
      panelClass: ['dialogs-main'],
      data: {
        bg : row
      }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }

  openDialogRackMaterialList(row:any) {
    this.matDialog.open(RackMaterialsListComponent, {
      panelClass: ['dialogs-main'],
      data: {
        rack : row
      }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }

  openDialogSaveMf() {
    this.matDialog.open(MfAddComponent, {
      panelClass: ['dialogs-lg'],
      data: {
        id_campana : this.campana.id
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.reload)  {
          this.loadListMf(); 
          this.tab.selectedIndex = 4;
        }
      }
    );
  }

  openDialogDocMf(row:any) {
    this.matDialog.open(MfDocComponent, {
      panelClass: ['dialogs-main'],
      data: {
        id_campana : this.campana.id,
        mf: row
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.reload)  {
          this.loadListMf(); 
          this.tab.selectedIndex = 4;
        }
      }
    );
  }

  openDialogCloseBox() {
    this.matDialog.open(CloseBoxesComponent, {
      panelClass: ['dialogs-lg'],
      data: {
        id_campana : this.campana.id
      }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }

  openUploadDistShipmentData(){
    this.matDialog.open(DistributionUploadShipmentsFileComponent, {
      panelClass: ['dialogs-main'],
      data: {
        campaign : this.campana
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.reload) this.loadListSh();
      }
    );
  }

  openEditDistShipmentData(row: any){
    this.matDialog.open(DistributionEditShipmentDataComponent, {
      panelClass: ['dialogs-main'],
      data: {
        shipment : row,
        id_campana : this.campana.id
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.reload) this.loadListSh();
      }
    );
  }
  
  openDialogMaterialIgs(row:any) {
    this.matDialog.open(DistributionMaterialsImgSlidersComponent, {
      panelClass: ['dialogs-lg'],
      data: {
        material : row
      }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }
}