<div class="modal-header">
    <h5 class="pt-4 ps-4">Campaña: <strong>{{ data.campaign.name }}</strong></h5>
    <button type="button" class="btn-close me-4" mat-dialog-close></button>
</div>
<mat-dialog-content class="mat-typography">
    <ng-container *ngIf="status === 0; else otherStatus">
        <div class="center-content">
            <p>Procesando archivo</p>
            <mat-spinner></mat-spinner>
        </div>
    </ng-container>
    <ng-template #otherStatus>
        <p *ngIf="status === 2" class="text-danger">Hubo un error en el proceso</p>
        <p *ngIf="status === 1" class="text-success">Archivo procesado correctamente</p>
        <span>Carga el archivo de guías ordenadas:</span>
        <form [formGroup]="form">
            <ngx-file-drag-drop [disabled]="validated" class="ngx-file_f" emptyPlaceholder="Arrastre o seleccione archivo" formControlName="blob" [formControl]="fileControl" (valueChanged)="onValueChange($event)">
            </ngx-file-drag-drop>
        </form>
    </ng-template>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button *ngIf="status === 1" mat-flat-button class="bg-success text-white" (click)="downloadFile()">Descargar <mat-icon>download</mat-icon></button>
    <button *ngIf="status !== 0" mat-flat-button class="bg-primary text-white" form="userForm" (click)="uploadFile()" type="submit">Guardar <mat-icon>save</mat-icon></button>
</mat-dialog-actions>