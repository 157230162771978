import { Injectable } from '@angular/core';
import { BranchModel, CampaignModel, CampaignModelPNP, CampaignTrackingModel, FileModel } from '../models/campaigns.model';
import { MainService } from './main.service';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CampaignsService {

  constructor(
    private main: MainService
  ) { }

  getCampaignsConfig(){
    return this.main.getRequest("/campaign/config/");
  }

  getCampaingList(){
    return this.main.getRequest("/campaign/show").pipe(
      map((resp: any) => {
        let campaignList: Array<CampaignModel> = [];
        if(resp.message == "Success") {          
          resp.data.forEach((e:any) => {
            campaignList.push(e);
          });
        }     
        return campaignList;
      })
    );
  }
  
  getCampaingsTrackingList(){
    return this.main.getRequest("/campaign/tracking/show").pipe(
      map((resp: any) => {
        let campaignList: Array<CampaignModel> = [];
        if(resp.message == "Success") {          
          resp.data.forEach((e:any) => {
            campaignList.push(e);
          });
        }     
        return campaignList;
      })
    );
  }

  getCampaign(id:string){
    return this.main.getRequest(`/campaign/show/${ id }`);
  }

  saveCampaign(campaignModel: CampaignModel){
    const { name, id_company, id_nest } = campaignModel;
    return this.main.postRequest({ name, id_company, id_nest }, "/campaign/create");
  }

  changeStatusCampaign(id: number, active: number){
    var campaignModel = new CampaignModel();
    campaignModel.id = id;
    campaignModel.active = active;
    return this.main.postRequest(campaignModel, "/campaign/edit/");
  }

  changeActiveCloudVideo(id: number, active: number){
    var campaignModel = { "id": id, "cloud_video": active };
    return this.main.postRequest(campaignModel, "/campaign/edit/");
  }
  
  changeQVideo(quality: number){
    return this.main.postRequest({ quality }, "/campaign/config/edit/");
  }


  editCampaign(campaignModel: CampaignModel, id_campaign: number){
    campaignModel.id = id_campaign;
    const { created_at, active, ...rest } = campaignModel;
    return this.main.postRequest(rest, "/campaign/edit");

  }

  deleteCampaign(id:string){
    return this.main.deleteRequest(`/campaign/delete/${ id }`);
  }

  getCampaignData(id:number){
    return this.main.getRequest(`/tracking/get-campaign-tracking-info/${ id.toString() }`).pipe(
      map((resp: any) => {
        let campaignList: Array<CampaignTrackingModel> = [];
        if(resp.message == "Success") {          
          resp.data.forEach((e:any) => {
            campaignList.push(e);
          });
        }     
        return campaignList;
      })
    );
  }

  getCompanies() {
    return this.main.getRequest("/campaign/companies/show");
  }

  getNestCampaigns(id:number) {
    return this.main.getRequest(`/campaign/companies/nest-campanas/${ id.toString() }`);
  }

  getCampaignListPNP(active: number, isTaping?:boolean){
    return this.main.postRequest({ active, isTaping },"/campaign/dashboard").pipe(
      map((resp: any) => {
        let campaignListPNP: Array<CampaignModelPNP> = [];

        if(resp.message == "Success") {          
          campaignListPNP = resp.data;
        } 
        
        return campaignListPNP;
      })
    );    
  }

  assignFile(file: FileModel, campaign: CampaignModel){
    return this.main.postRequest({ file,campaign }, "/campaign/assign-file");
  }

  updateTracking (id:string) {
    return this.main.getRequest(`/tracking/update-tracking/${ id }`);
  }

  getActiveCampaign(id: number){
    return this.main.postRequest({ id_campana: id },"/campaign/dashboard_taping").pipe(
      map((resp: any) => {
        return resp.data;
      })
    );    
  }
}
