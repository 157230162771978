import { Component, ViewChild, Renderer2, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DistributionUploadFileComponent } from '../shared/dialogs/distribution-upload-file/distribution-upload-file.component';
import { DistributionsService } from 'src/app/services/distributions.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/services/main.service';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DistributionUploadGuidesFileComponent } from '../shared/dialogs/distribution-upload-guides-file/distribution-upload-guides-file.component';
import { DistributionUploadRacksFileComponent } from '../shared/dialogs/distribution-upload-racks-file/distribution-upload-racks-file.component';
import { BoxMaterialsListComponent } from '../shared/dialogs/box-materials-list/box-materials-list.component';
import { BgMaterialsListComponent } from '../shared/dialogs/bg-materials-list/bg-materials-list.component';
import { RackMaterialsListComponent } from '../shared/dialogs/rack-materials-list/rack-materials-list.component';
import Swal from 'sweetalert2';
import { MatTabGroup } from '@angular/material/tabs';
import { MfAddComponent } from '../shared/dialogs/mf-add/mf-add.component';
import { MfDocComponent } from '../shared/dialogs/mf-doc/mf-doc.component';
import { CloseBoxesComponent } from '../shared/dialogs/close-boxes/close-boxes.component';
import { DistributionUploadShipmentsFileComponent } from '../shared/dialogs/distribution-upload-shipments-file/distribution-upload-shipments-file.component';
import { DistributionEditShipmentDataComponent } from '../shared/dialogs/distribution-edit-shipment-data/distribution-edit-shipment-data.component';
import { DistributionMaterialsUploadImgsComponent } from '../shared/dialogs/distribution-materials-upload-imgs/distribution-materials-upload-imgs.component';
import { DistributionMaterialsImgSlidersComponent } from '../shared/dialogs/distribution-materials-img-sliders/distribution-materials-img-sliders.component';
import { DistributionUploadOrderedGuidesComponent } from '../shared/dialogs/distribution-upload-ordered-guides/distribution-upload-ordered-guides.component';
import { ValidationQrComponent } from '../shared/dialogs/validation-qr/validation-qr.component';
import * as printJS from 'print-js';
import { HttpClient } from '@angular/common/http';
import { PeriodosComponent } from '../shared/dialogs/periodos/periodos.component';

@Component({
  selector: 'app-distribution',
  templateUrl: './distribution.component.html',
  styleUrls: ['./distribution.component.scss']
})
export class DistributionComponent {
  @ViewChild('tabGroup', {static: false}) tab!: MatTabGroup;  
  campana!: any;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  dsSucursales!: MatTableDataSource<any>;
  colsSucursales: string[] = ['box_id', 'id_ext', 'filial', 'zona', 'sucursal', 'consecutivo', 'status', 'tracking_number', 'archivo', 'actions'];

  @ViewChild("paginatorBG") paginatorBG!: MatPaginator;
  @ViewChild("sortBG") sortBG!: MatSort;
  dsBGs!: MatTableDataSource<any>;
  colsBGs: string[] = ['bag_id', 'BG', 'cantidad', 'cantidad_sku', 'cantidad_pza', 'available', 'actions'];

  @ViewChild("paginatorRK") paginatorRK!: MatPaginator;
  @ViewChild("sortRK") sortRK!: MatSort;
  dsRacks!: MatTableDataSource<any>;
  colsRacks: string[] = ['nombre', 'line', 'tipo', 'actions'];

  @ViewChild("paginatorMat") paginatorMat!: MatPaginator;
  @ViewChild("sortMat") sortMat!: MatSort;
  dsMaterials!: MatTableDataSource<any>;
  colsMat: string[] = ['sku', 'nombre', 'tipo', 'cantidad', 'status','validacion_qr', 'actions'];

  @ViewChild("paginatorMf") paginatorMf!: MatPaginator;
  @ViewChild("sortMf") sortMf!: MatSort;
  dsMf!: MatTableDataSource<any>;
  colsMf: string[] = ['nombre', 'paqueteria', 'empresa', 'recolector', 'cantidad', 'fecha_salida', 'actions'];

  @ViewChild("paginatorSh") paginatorSh!: MatPaginator;
  @ViewChild("sortSh") sortSh!: MatSort;
  dsSh!: MatTableDataSource<any>;
  colsSh: string[] = ['actions', 'status', 'id', 'consecutivo', 'address','reference', 'provider', 'carrier', 'service', 'weight', 'length', 'width', 'height'];
  
  @ViewChild("paginatorFiles") paginatorFiles!: MatPaginator;
  @ViewChild("sortFiles") sortFiles!: MatSort;
  dsArchivos!: MatTableDataSource<any>;
  colArchivos: string[] = ['id', 'nombre', 'total_guias', 'fecha', 'imprimir'];

  @ViewChild(MatPaginator) paginatorGeneral!: MatPaginator;
  dsGeneral!: MatTableDataSource<any>;
  colsGeneral: string[] = ['consecutivo','fecha', 'cajas', 'periodos', 'actions'];

  guias_compradas: number = 0;
  total_guias: number = 0;
  error_guias: number = 0;
  total_locales: number = 0;
  datos_envio: any = [];
  camp_id: number = 0;
  isLoadingShipments = false;
  progress: number = 0;
  deteniendo_compra : boolean = false;
  procesando: any = [];

  fechaInicio: Date | null = null;
  fechaFin: Date | null = null;
  rowsDias: any[] = [];
  flagData: boolean = false;

  constructor(
    private distService : DistributionsService,
    private campaignS: CampaignsService,
    private main : MainService,
    private _params: ActivatedRoute,
    private _router : Router,
    private matDialog: MatDialog,
    private dialogQrValidation: MatDialog,
    private dialogPeriodos: MatDialog,
    private http: HttpClient,
    private renderer: Renderer2
  ) { 
    this._params.params.subscribe(params => {
      if(params["id"]){
        this.loadCampaignData(params["id"]);
        this.camp_id = params["id"];
      }else{
        this._router.navigateByUrl('/admin/dashboard');
      } 
    });
  }
   ngOnInit(): void {
  }

  //Quita el form field wrapper despues de cargar contenido
  ngAfterViewInit(): void {
    const style = this.renderer.createElement('style');
    style.innerHTML = `
      .input-form-field .mat-mdc-form-field-subscript-wrapper {
        display: none !important;
      }
    `;
    this.renderer.appendChild(document.head, style);
  }
  
  applyFilter(event: Event, ds: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    ds.filter = filterValue.trim().toLowerCase();
    if (ds.paginator) ds.paginator.firstPage();
  }

  loadCampaignData(camp_id: any){
    this.campaignS.getCampaign(camp_id).subscribe(
      (result : any) => {
        if(result.data) {
          this.campana = result.data[0];
          this.loadDistData(this.campana.id);
          this.loadFilesGuias(this.campana.id);
          this.loadGeneralData(this.campana.id);
        }
      },
      (err : any) => {
        console.log(err);
      }
    );
  }
  
  loadDistData(camp_id: any){
    this.distService.getSucBgs(camp_id).subscribe(
      (result : any) => {
        if(result.data) {
          if(result.data.sucursales) {
            this.dsSucursales = new MatTableDataSource(result.data.sucursales);
            this.dsSucursales.paginator = this.paginator;
            this.dsSucursales.sort = this.sort;

            result.data.bg.map((e:any)=> { 
              const status_txt = e.status==1?"En existencia":(e.status==2?"Faltante":"Retirar de linea");
              return Object.assign(e, {status_txt});
            })
            this.dsBGs = new MatTableDataSource(result.data.bg);
            this.dsBGs.paginator = this.paginatorBG;
            this.dsBGs.sort = this.sortBG;

            this.dsRacks = new MatTableDataSource(result.data.racks);
            this.dsRacks.paginator = this.paginatorRK;
            this.dsRacks.sort = this.sortRK;
            
            result.data.materiales.map((e:any)=> { 
              const status_txt = e.status==1?"En existencia":(e.status==2?"Faltante":"Retirar de linea");
              return Object.assign(e, {status_txt});
            })
            this.dsMaterials = new MatTableDataSource(result.data.materiales);
            this.dsMaterials.paginator = this.paginatorMat;
            this.dsMaterials.sort = this.sortMat;
          }
        }
      },
      (err : any) => {
        console.log(err);
      }
    );
  }

  onTabChanged(ev: any) {
    let selected_tab = ev.index;
    switch (selected_tab) {
      case 4:
        if(!this.dsMf) this.loadListMf(); 
        break;
      case 5: 
        if(!this.dsSh) this.loadListSh(); 
        break
      ;
    }
    
  }

  loadListMf(){
    this.distService.getMf(this.campana.id).subscribe(
      (result : any) => {
        if(result.data) {
          this.dsMf = new MatTableDataSource(result.data);
          this.dsMf.paginator = this.paginatorMf;
          this.dsMf.sort = this.sortMf;
        }
      },
      (err : any) => {
        console.log(err);
      }
    );
  }
  
  loadListSh(){
    this.distService.getDisShipmentData(this.campana.id).subscribe(
      (result : any) => {
        if(result.data) {
          this.datos_envio = result.data;
          this.guias_compradas = result.data.filter((e:any)=>e.status=="success").length;
          this.error_guias = result.data.filter((e:any)=>e.status=="error").length;
          this.total_locales = result.data.filter((e:any)=>e.carriers.toLowerCase()=="local").length;
          this.total_guias = result.data.length;
          this.progress = Math.floor((this.guias_compradas * 100) / (this.total_guias - this.total_locales));
          this.dsSh = new MatTableDataSource(this.datos_envio);
          this.dsSh.sort = this.sortSh;
          this.dsSh.paginator = this.paginatorSh;
        }
      },
      (err : any) => {
        console.log(err);
      }
    );
  }

  
  stopGetLabels() {
    this.deteniendo_compra = true;
    Swal.fire({
      icon: 'info',
      title: 'Deteniendo proceso...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
  }

  loadGetLabels() {
    let arr_ids = this.datos_envio.filter((e:any)=>e.status != "success"&&e.status != "in_progress"&&e.carriers.toLowerCase()!="local").map((e:any)=>e.id);
    let resultado = [];

    for (var i = 0; i < arr_ids.length; i += 50) {
        resultado.push(arr_ids.slice(i, i + 50));
    }
    const res_gl = this.getLabels(resultado, 0);
  }
  
  getLabels(arr: any, i: number) {
    this.procesando = arr[i];
    this.isLoadingShipments = true;
    this.distService.getLabels(this.camp_id, arr[i]).subscribe(
      (result : any) => {
        if(result.data) {
          this.datos_envio = this.datos_envio.map((e:any)=> {
            const obj_res = result.data.find((d:any)=> d.id==e.id);
            return Object.assign(e, { status: e.status!="success"?obj_res?.status:e.status, label: e.status!="success"?obj_res?.label:e.label });
          });

          this.guias_compradas = this.datos_envio.filter((e:any)=>e.status=="success").length;
          this.error_guias = result.data.filter((e:any)=>e.status=="error").length;
        }

        if(!this.deteniendo_compra) {
          if((i+1) < arr.length) {
            this.progress = Math.floor((this.guias_compradas * 100) / (this.total_guias - this.total_locales));
            this.getLabels(arr, (i+1));
          }
          else {
            this.progress = 100;
            Swal.fire({
              icon: 'success',
              title: 'Proceso finalizado',
              text: 'Proceso de compra finalizado!'          
            });
            this.isLoadingShipments = false;
          }
        }
        else {
          this.loadListSh();
          Swal.fire({
            icon: 'success',
            title: 'Proceso detenido',
            text: 'Proceso de compra detenido por el usuario.'
          });
          this.isLoadingShipments = false;
        }
        this.procesando = [];
      },
      (err : any) => {
        console.log(err);
        Swal.fire({
          icon: 'success',
          title: 'Proceso detenido',
          text: 'Proceso de compra detenido por el sistema.'
        });
        this.isLoadingShipments = false;
      }
    );
  }

  changeMatStatus(row: any, status: number, type: string){
    const camp_id = this.campana.id;
    const id = row.id;
    Swal.fire({
      title: '¿Estás seguro que deseas modificar el estatus del material?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed && ["mat", "bg"].includes(type)) {
        this.distService.changeMatStatus(camp_id, id, status, type).subscribe(
          (result : any) => {
            if(result.message == "Success") {
              Swal.fire({
                icon: 'success',
                title: 'Guardado',
                text: 'Estatus actualizado de manera correcta!'          
              });
              this.loadDistData(this.campana.id);
              if(type == "mat") this.tab.selectedIndex = 3;
              if(type == "bg") this.tab.selectedIndex = 1;
            }
          },
          (err : any) => {
            console.log(err);
            Swal.fire({
              icon: 'error',
              title: 'Error al guardar',
              text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
            });
          }
        );
      }
    });
  }

  
  generateShipmentsFile(){
    const camp_id = this.campana.id;
    Swal.fire({
      title: '¿Estás seguro que deseas generar el archivo de guias?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        Swal.fire({
          icon: 'info',
          title: 'Generando archivo...',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
        this.distService.generateShipmentsFile(camp_id).subscribe(
          (result : any) => {
            if(result.message == "Success") {
              window.open(result.data, "_blank");
              Swal.fire({
                icon: 'success',
                title: 'Archivo creado',
                text: 'Archivo generado de manera correcta!'
              });
            }
          },
          (err : any) => {
            console.log(err);
            Swal.fire({
              icon: 'error',
              title: 'Error al generar',
              text: 'Ocurrió un error al generar el archivo de guias, por favor vuelve a intentarlo.'
            });
          }
        );
      }
    });
  }

  downloadFileDistData(){
    this.distService.getFileSucBgs(this.campana.id).subscribe(
      (result : any) => {
        if(result.data) {
          window.open(result.data, "_blank");
        }
      },
      (err : any) => {
        console.log(err);
      }
    );
  }

  openUploadDist(){
    this.matDialog.open(DistributionUploadFileComponent, {
      panelClass: ['dialogs-lg'],
      data: {
        campaign : this.campana
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.reload) this.loadCampaignData(this.campana.id);
      }
    );
  }

  openUploadRackDist(){
    this.matDialog.open(DistributionUploadRacksFileComponent, {
      panelClass: ['dialogs-lg'],
      data: {
        campaign : this.campana
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.reload) this.loadCampaignData(this.campana.id);
      }
    );
  }

  openUploadGuides(){
    this.matDialog.open(DistributionUploadGuidesFileComponent, {
      panelClass: ['dialogs-sm'],
      data: {
        campaign : this.campana
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.data) window.open(resp.data, "_blank");
      }
    );
  }

  openUploadOrderedGuides(){
    this.matDialog.open(DistributionUploadOrderedGuidesComponent, {
      panelClass: ['dialogs-sm'],
      data: {
        campaign : this.campana
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.data){
          //window.open(resp.data, "_blank");
          this.loadFilesGuias(this.campana.id)
        } 
      }
    );
  }

  openUploadMaterialImgs(){
    this.matDialog.open(DistributionMaterialsUploadImgsComponent, {
      panelClass: ['dialogs-sm'],
      data: {
        campaign : this.campana
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.reload) {
          this.loadCampaignData(this.campana.id);
          this.tab.selectedIndex = 3;
        }
      }
    );
  }

  openDialogMaterialList(row:any) {
    this.matDialog.open(BoxMaterialsListComponent, {
      panelClass: ['dialogs-main'],
      data: {
        box : row
      }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }

  openDialogBGMaterialList(row:any) {
    this.matDialog.open(BgMaterialsListComponent, {
      panelClass: ['dialogs-main'],
      data: {
        bg : row
      }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }

  openDialogRackMaterialList(row:any) {
    this.matDialog.open(RackMaterialsListComponent, {
      panelClass: ['dialogs-main'],
      data: {
        rack : row
      }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }

  openDialogSaveMf() {
    this.matDialog.open(MfAddComponent, {
      panelClass: ['dialogs-lg'],
      data: {
        id_campana : this.campana.id
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.reload)  {
          this.loadListMf(); 
          this.tab.selectedIndex = 4;
        }
      }
    );
  }

  openDialogDocMf(row:any) {
    this.matDialog.open(MfDocComponent, {
      panelClass: ['dialogs-main'],
      data: {
        id_campana : this.campana.id,
        mf: row
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.reload)  {
          this.loadListMf(); 
          this.tab.selectedIndex = 4;
        }
      }
    );
  }

  openDialogCloseBox() {
    this.matDialog.open(CloseBoxesComponent, {
      panelClass: ['dialogs-lg'],
      data: {
        id_campana : this.campana.id
      }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }

  openUploadDistShipmentData(){
    this.matDialog.open(DistributionUploadShipmentsFileComponent, {
      panelClass: ['dialogs-main'],
      data: {
        campaign : this.campana
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.reload) this.loadListSh();
      }
    );
  }

  openEditDistShipmentData(row: any){
    this.matDialog.open(DistributionEditShipmentDataComponent, {
      panelClass: ['dialogs-main'],
      data: {
        shipment : row,
        id_campana : this.campana.id
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.reload) this.loadListSh();
      }
    );
  }
  
  openDialogMaterialIgs(row:any) {
    this.matDialog.open(DistributionMaterialsImgSlidersComponent, {
      panelClass: ['dialogs-lg'],
      data: {
        material : row
      }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }

  openDialogValidationQr(element: any): void {
  
    const dialogRef = this.dialogQrValidation.open(ValidationQrComponent, {
      panelClass: 'dialogs-sm',
      data: element 
    });

    dialogRef.afterClosed().subscribe(result => {

      // Guarda o edita
      if(result.delete == 0){
        if (result) {

          element.tipo_validacion = result.option; 
          element.max_length = result.maxLength; 
          element.min_length = result.minLength; 
          element.bitUnico = result.unico; 
          element.arreglo = result.arreglo.join(); 
  
          result.arreglo = result.arreglo.length == 0  ? null : result.arreglo.join(',');
          result.minLength = result.minLength == ''  ? null : result.minLength;
          result.maxLength = result.maxLength == ''  ? null : result.maxLength;
  
          const newRecord = {
            idCampana: this.camp_id,
            idMaterial: element.id,
            selectedOption : result.option,
            optionsArreglo: result.arreglo,
            esUnico: result.unico,
            minLength: result.minLength,
            maxLength: result.maxLength,
            idValidation: element.idValidation ?? 0
          };
  
          this.campaignS.setValidationQr(newRecord).subscribe(
            (result:any)=>{
              if(result.message == 'Success') {
                Swal.fire({
                  icon: 'info',
                  title: 'Guardado',
                  text:'',
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  showConfirmButton: false,
                  timer: 1000,
                });   
              }
              else {
                Swal.fire({
                  icon: 'error',
                  title: 'Error al guardar',
                  text: 'Ocurrió un error, favor de informar al equipo de soporte.',
                  timer: 3000,
                  showConfirmButton: false
                });
              }
              element.idValidation = result.id;
            },
            (err:any)=>{
              this.main.HideLoading();
              Swal.fire({
                icon: 'error',
                title: 'Error al guardar',
                text: 'Ocurrió un error, favor de informar al equipo de soporte.',
                timer: 3000,
                showConfirmButton: false
              });
          });
        }
      }
      // Elimina
      else if(result.delete == 1){

        Swal.fire({
          icon: 'warning',
          title: '¿Seguro que quieres desactivar la validación?',
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: 'Sí, desactivar',
          cancelButtonText: 'Cancelar',
          customClass: {
            confirmButton: 'custom-confirm-button',
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((resultSwal) => {

          const objValidation = { id: result.idValidation} ;
          if (resultSwal.isConfirmed) {
            this.campaignS.setDeleteValidacionQr(objValidation).subscribe(
              (resultS:any)=>{
                if(resultS.message == 'Success') {
                  Swal.fire({
                    icon: 'info',
                    title: 'Guardado',
                    text:'',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    timer: 1000,
                  });   
                  element.tipo_validacion = null; 
                  element.max_length = null; 
                  element.min_length = null; 
                  element.bitUnico = null; 
                  element.arreglo = null;
                  element.idValidation = null;
                }
                else {
                  Swal.fire({
                    icon: 'error',
                    title: 'Error al guardar',
                    text: 'Ocurrió un error, favor de informar al equipo de soporte.',
                    timer: 3000,
                    showConfirmButton: false
                  });
                }
              },
              (err:any)=>{
                this.main.HideLoading();
                Swal.fire({
                  icon: 'error',
                  title: 'Error al guardar',
                  text: 'Ocurrió un error, favor de informar al equipo de soporte.',
                  timer: 3000,
                  showConfirmButton: false
                });
            });
          }
        });

        
      }
          
    });
  }

  loadFilesGuias(camp_id: any){
    this.distService.getFilesGuides(camp_id).subscribe(
      (result : any) => {
        if(result.data) {
          
          result.data.map((e:any)=> { 
            const elements = e.consecutivos.split(",");
            const total_guias = elements.length;
            return Object.assign(e, {total_guias});
          })

          this.dsArchivos = new MatTableDataSource(result.data);
          this.dsArchivos.paginator = this.paginatorFiles;
          this.dsArchivos.sort = this.sortFiles;

        }
      },
      (err : any) => {
        console.log(err);
      }
    );
  }

  setPrint(row: any){


    this.distService.setPrintFile(row.id)
      .subscribe(
        (response: any) => {
          row.print = 1;
          const fileURL = URL.createObjectURL(response); // Crea una URL temporal para el archivo
          printJS({ printable: fileURL, type: 'pdf' }); // Llama a Print.js para imprimir el PDF
        },
        (error: any) => {
          console.error('Error descargando el archivo:', error);
        }
      );

  }

  validaDataGeneral() {
    if (!this.fechaInicio || !this.fechaFin) {
      Swal.fire({
        icon: 'warning',
        title: 'Campos incompletos',
        text: 'Por favor, selecciona ambas fechas para continuar.',
        confirmButtonText: 'Ok',
      });
      return;
    }

    const inputsCajas = this.rowsDias.some(row => !row.cajas || row.cajas <= 0)

    if(inputsCajas){
      Swal.fire({
        icon: 'warning',
        title: 'Campos incompletos',
        text: 'Por favor, ingresa un número total en cajas a encintar.',
        confirmButtonText: 'Ok',
      });
      return;
    }

    if(this.flagData == true){
      Swal.fire({
        title: "Atención",
        text: "Se detectaron objetivos anteriormente guardados, ¿seguro que quieres actualizarlos?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, confirmar",
        cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) {
              this.saveDataGeneral()
            }
        });
    }
    else{
      this.saveDataGeneral()
    }
 
  }

  saveDataGeneral(){
    const data = {
      id_campana: this.camp_id,
      fechaInicio: this.fechaInicio,
      fechaFin: this.fechaFin,
      flagData: this.flagData,
      rows: this.rowsDias
    };

    this.mostrarLoading();
    this.distService.setDataGeneralCampaign(data).subscribe(
        (response: any) => {
          Swal.close(); // Cerrar loading
          if(response.message == "Success") {
            Swal.fire({
              icon: 'success',
              title: 'Guardado',
              text: ''          
            });
            this.rowsDias = response.data;
            this.dsGeneral = new MatTableDataSource(this.rowsDias);
          }
        },
        (error: any) => {
          Swal.close(); // Cerrar loading
          console.error('Error al guardar:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
          });
        }
    );
  }

  calcularDias() {
    if (!this.fechaInicio || !this.fechaFin) {
      return;
    }

    const start = new Date(this.fechaInicio);
    const end = new Date(this.fechaFin);
    let currentDate = start;

    if (end < start) {
      Swal.fire({
        icon: 'warning',
        title: 'Advertencia',
        text: 'La fecha de fin debe ser posterior a la fecha de inicio.',
        confirmButtonText: 'Ok',
      });
      return;
    }

    //Para saber si ya se guardaron objetivos
    if(this.rowsDias?.[0]?.idObjetivo ?? 0 > 0){
      Swal.fire({
        title: "Atención",
        text: "Ya existe información en objetivos, ¿seguro que quieres sobreescribir los datos en pantalla?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, confirmar",
        cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) {
              this.flagData = true;
              this.llenarObjetivos(currentDate,end)
            }
        });
    }
    else{
      this.llenarObjetivos(currentDate,end)
    }

  }


  llenarObjetivos(currentDate: any, end:any){
    this.rowsDias = [];

    while (currentDate <= end) {
      this.rowsDias.push({
        fecha: new Date(currentDate),
        cajas: 0,
        idObjetivo: 0,
        numPeriodos: 0
      });

      // Avanzar al siguiente día
      currentDate.setDate(currentDate.getDate() + 1);
    }

    //Si hay cajas en la campaña
    if(this.dsSucursales != undefined){

      const distribucionCajas = this.distribuirCajas(this.dsSucursales.filteredData.length, this.rowsDias.length);
      //Se actualiza el numero de cajas
      this.rowsDias.map((e:any, index: number) => {
        e.cajas = distribucionCajas[index];
      })
    }

    this.dsGeneral = new MatTableDataSource(this.rowsDias);
  }

  loadGeneralData(camp_id: any){
    this.distService.getGeneralData(camp_id).subscribe(
      (result : any) => {
        if(result.data) {

          this.rowsDias = result.data;

          const fechaOriginal1 = this.campana.fecha_inicio;
          const fechaOriginal2 = this.campana.fecha_fin;

          if(fechaOriginal1 != null){
            const [year, month, day] = fechaOriginal1.split('-').map(Number);
            const fecha1 = new Date(year, month - 1, day);
  
            const [year2, month2, day2] = fechaOriginal2.split('-').map(Number);
            const fecha2 = new Date(year2, month2 - 1, day2);
  
            this.fechaInicio = fecha1;
            this.fechaFin = fecha2;
          }
  
          this.dsGeneral = new MatTableDataSource(this.rowsDias);

        }
      })
  }
          
  downloadCajas(){
    this.distService.getFileCajas(this.campana.id).subscribe(
      (result : any) => {
        if(result.data) {
          window.open(result.data, "_blank");
        }
      },
      (err : any) => {
        console.log(err);
      }
    );
  }

  openDialogPeriodos(element: any): void {

    element.campana = this.camp_id;
  
    const dialogRef = this.dialogPeriodos.open(PeriodosComponent, {
      panelClass: 'dialogs-md',
      data: element 
    });

    dialogRef.afterClosed().subscribe(result => { 
      this.loadGeneralData(element.campana)
    });

  }

  distribuirCajas(totalCajas: number, numeroDias: number) {
  
    const cajasPorDia = Math.floor(totalCajas / numeroDias);
    const resto = totalCajas % numeroDias;

    // Crear un array para almacenar la distribución de cajas
    const distribucion = Array(numeroDias).fill(cajasPorDia);

    // Distribuir las cajas sobrantes
    for (let i = 0; i < resto; i++) {
        distribucion[i]++;
    }

    return distribucion;
  }

  mostrarLoading() {
    Swal.fire({
      title: 'Guardando...',
      text: 'Por favor, espera.',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
  }

  downloadBolsas(){
    this.distService.getFileBolsas(this.campana.id).subscribe(
      (result : any) => {
        if(result.data) {
          window.open(result.data, "_blank");
        }
      },
      (err : any) => {
        console.log(err);
      }
    );
  }

  downloadMateriales(){
    this.distService.getFileMateriales(this.campana.id).subscribe(
      (result : any) => {
        if(result.data) {
          window.open(result.data, "_blank");
        }
      },
      (err : any) => {
        console.log(err);
      }
    );
  }

  validateInput(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault(); // Evita que se escriban letras o caracteres especiales
    }
  }
  
}