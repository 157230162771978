import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { LayoutComponent } from './components/shared/layout/layout.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { UsersComponent } from './components/users/users.component';
import { CampaignComponent } from './components/campaign/campaign.component';
import { DistributionComponent } from './components/distribution/distribution.component';
import { MaterialsComponent } from './components/distribution/materials/materials.component';
import { DashboardPnpComponent } from './components/dashboard-pnp/dashboard-pnp.component';
import { DashboardPnpMatComponent } from './components/dashboard-pnp-mat/dashboard-pnp-mat.component';
import { DashboardPnpBoxComponent } from './components/dashboard-pnp-box/dashboard-pnp-box.component';
import { TapingComponent } from './components/taping/taping.component';
import { MonitoringComponent } from './components/monitoring/monitoring.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', component: LoginComponent },
  {
    path: 'admin', canActivate: [AuthGuard], component: LayoutComponent, data: {expectedRoles: ['Any']},
    children: [      
      { path: '', canActivate: [AuthGuard], component: DashboardComponent, data: {expectedRoles: ['Any']} },
      { path: 'dashboard', canActivate: [AuthGuard], component: DashboardComponent, data: {expectedRoles: ['Any']} },
      { path: 'dashboardpnp', canActivate: [AuthGuard], component: DashboardPnpComponent, data: {expectedRoles: ['Any']} },
      { path: 'dashboardmatpnp/:id', canActivate: [AuthGuard], component: DashboardPnpMatComponent, data: {expectedRoles: ['Any']} },
      { path: 'dashboardboxpnp/:id', canActivate: [AuthGuard], component: DashboardPnpBoxComponent, data: {expectedRoles: ['Any']} },
      { path: 'users', canActivate: [AuthGuard], component: UsersComponent, data: {expectedRoles: ['Any']} },
      { path: 'campaign', canActivate: [AuthGuard], component: CampaignComponent, data: {expectedRoles: ['Any']} },
      { path: 'distribution/:id', canActivate: [AuthGuard], component: DistributionComponent, data: {expectedRoles: ['Any']} },
      { path: 'materials', canActivate: [AuthGuard], component: MaterialsComponent, data: {expectedRoles: ['Any']} },
      { path: 'taping', canActivate: [AuthGuard], component: TapingComponent, data: {expectedRoles: ['Any']} },
      { path: 'monitoring', canActivate: [AuthGuard], component: MonitoringComponent, data: {expectedRoles: ['Any']} },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
