<div class="container-fluid">
    <div class="page-title">
        <div class="row">
            <!-- Select Campaña -->
            <div class="col-6">
                <ol id="selCampaign" class="breadcrumb me-3 mb-0 ms-3">
                    <li class="breadcrumb-item font-blue dropdown drop-container">
                        <mat-spinner class="ms-2" color="accent" diameter="20" *ngIf="isLoading; else title"></mat-spinner>
                        <ng-template #title>
                            {{ this.campaignSelected?.name }}
                            <i class="ms-2 arrow down"></i>
                            <div class="drop bg-white">
                                <ul>
                                    <li (click)="setCampaignData(campaign)" class="list-item" *ngFor="let campaign of campaignList">
                                        <span href="#">{{campaign.name}}</span>
                                    </li>
                                </ul>
                            </div>
                        </ng-template>
                    </li>
                </ol>
            </div>
            <!-- Info en el header -->
            <div class="col-6 d-flex align-items-right">
                <div class="col-4">
                    <span><strong class="me-2">Compañía:</strong>  {{this.compania}}</span>
                </div>
                <div class="col-4">
                    <span><strong class="me-2">Fecha Inicio:</strong>  {{this.fecha_inicio | date: 'dd/MM/yyy'}}</span> 
                </div>
                <div class="col-4">
                    <span><strong class="me-2">Fecha Fin:</strong>  {{this.fecha_fin | date: 'dd/MM/yyy'}}</span>
                </div>
            </div>
        </div>
    </div>
    <!-- Contendido -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 monitoring-tabs">
                <mat-tab-group mat-stretch-tabs="false">
                    <!-- TAB PROGRESO -->
                    <mat-tab label="Progreso">
                        <div class="row mt-3">
                            <!-- Objetivo General -->
                            <div class="col-12 col-md-6">
                                <div class="card">
                                    <div class="card-body" style="padding: 15px !important;">
										<h3 class="h5">Objetivo general</h3>
                                        <div class="container d-flex justify-content-center">
											<div class="row">
                                                <div class="col-md-8">
                                                    <div class="row">
                                                        <div class="row col-12 mt-2">

															<div class="col-md-1">
																<mat-icon class="align-middle icono-cafe">inventory_2</mat-icon>
															</div>
															<div class="col-md-5"> 
																<strong>Total de cajas por encintar</strong>
																<label class="mt-2">{{this.total_cajas}} cajas</label>
															</div>
															<div class="col-md-1">
																<mat-icon class="align-middle" color="accent">timer</mat-icon>
															</div>
															<div class="col-md-5">
																<strong>Duración de la campaña</strong> 
																<label class="mt-2">{{this.duracion_campana}} dias</label>
															</div>
                                                    	</div>
														<div class="row col-12 mt-3">
															<hr style="border: 1px solid black; width: 100%;">
														</div>
														<div class="row col-12 mt-3">
															<div class="col-md-6">
																<mat-icon class="align-middle" color="accent" style="position: absolute; z-index: 100; margin-top: 4.1rem; margin-left: 2.4rem;">sports_score</mat-icon>
																<div class="progress-container">
																	<svg class="progress-circle" viewBox="0 0 36 36">
																		<path class="circle-bg"
																				d="M18 2.0845
																				a 15.9155 15.9155 0 0 1 0 31.831
																				a 15.9155 15.9155 0 0 1 0-31.831" />
																		<path class="circle"
																				[attr.stroke-dasharray]="progress + ', 100'"
																				d="M18 2.0845
																				a 15.9155 15.9155 0 0 1 0 31.831
																				a 15.9155 15.9155 0 0 1 0-31.831" />
																				
																		<text x="21" y="20.35" class="percentage">     														
																			{{ progress }}% </text>
																	</svg>
																</div>
															</div>
															<div class="col-md-6 mt-4">
																<strong>Objetivo diario</strong>
																<div *ngIf="this.objetivo_diario > 0">
																	<label class="mt-1">Encintar {{this.objetivo_diario}} cajas durante las siguientes {{this.hr_restantes}} horas</label>
																</div>
																<div *ngIf="this.objetivo_diario == 0">
																	<label class="mt-1">No se encontró un objetivo para la fecha actual</label>
																</div>
															</div>
														</div>
                                                	</div>
                                        		</div>
												<div class="col-md-4 d-flex justify-content-center align-items-center">
													<img class="img-fluid" src="/assets/images/monitoring/Objetivo-general.jpg" alt="Objetivo general">
												</div>
                                    		</div> 
                                		</div>
                            		</div>
                        		</div>
                    		</div>
                            <!-- Cards (Total sku, bolsas, zonas, picks, materialg, sucursales)-->
                            <div class="col-md-6">
                                <div class="row text-center">
                                    <div class="col-md-4">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="col-md-12 mt-2">
													<mat-icon class="icono-verde1">inventory</mat-icon>
												</div>
												<div class="col-md-12 mt-3" style="font-size: 16px;">
													<strong>Total SKU</strong>
												</div>
												<label class="mt-1" style="font-size: 16px;">{{this.totalSKU}}</label> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="col-md-12 mt-2">
													<mat-icon class="icono-verde2">shopping_bag</mat-icon>
												</div>
												<div class="col-md-12 mt-3" style="font-size: 16px;">
													<strong>Bolsas</strong>
												</div>
												<label class="mt-1" style="font-size: 16px;">{{this.bolsas}}</label>                                           
											</div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="card">
                                            <div class="card-body">
												<div class="col-md-12 mt-2">
													<mat-icon class="icono-naranja">map</mat-icon>
												</div>
												<div class="col-md-12 mt-3" style="font-size: 16px;">
													<strong>Zonas</strong>
												</div>
												<label class="mt-1" style="font-size: 16px;">{{this.zonas}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="row text-center">
                                    <div class="col-md-4">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="col-md-12 mt-2">
													<mat-icon class="icono-rojo">barcode_reader</mat-icon>
												</div>
												<div class="col-md-12 mt-3" style="font-size: 16px;">
													<strong>Total de picks</strong>
												</div>
												<label class="mt-1" style="font-size: 16px;">{{this.totalPicks}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="card">
                                            <div class="card-body">
												<div class="col-md-12 mt-2">
													<mat-icon class="icono-amarillo">design_services</mat-icon>
												</div>
												<div class="col-md-12 mt-3" style="font-size: 16px;">
													<strong>Material G</strong>
												</div>
												<label class="mt-1" style="font-size: 16px;">{{this.materialG}}</label>                                            
											</div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="col-md-12 mt-2">
													<mat-icon class="icono-azul">store</mat-icon>
												</div>
												<div class="col-md-12 mt-3" style="font-size: 16px;">
													<strong>Sucursales</strong>
												</div>
												<label class="mt-1" style="font-size: 16px;">{{this.sucursales}}</label>  
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
						<!-- Progreso por periodos -->
						<div class="row">
							<div class="col-12 col-md-12">
                                <div class="card">
                                    <div class="card-body" style="padding: 15px !important;">
                                        <div class="container-fluid">
											<div class="row">
												<div class="col-md-10">
													<h4 class="h5">Progreso por periodos</h4>
													<label class="mt-1" *ngIf="periodos.length > 0">Tus periodos se encuentran divididos de la siguiente manera: </label>
												</div>
												<div class="col-md-2 d-flex justify-content-end align-items-center">
													<img style="width: 50px; height: 55px;" src="/assets/images/monitoring/reloj.jpg" alt="Periodos">
												</div>
                                    		</div> 
											<div *ngIf="periodos.length > 0">
												<div class="row mt-3">
													<app-progress-bar-periodos [periodos]="periodos" [progress]="progress" [cajas_encintadas]="total_cajas_encintadas" [total_cajas]="total_cajas"></app-progress-bar-periodos>
												</div>
												<div class="row alert alert-warning custom-alert mt-4" role="alert">
													<div class="text-center">
														¡El final está cerca! Solo queda un último esfuerzo para llegar al objetivo final. 
														<br> Mantén el ritmo y completa las últimas cajas.
													</div>
												</div>
											</div>
											<div *ngIf="periodos.length == 0" class="text-center">
												<label class="mt-2 mb-2">No se encontraron periodos para la fecha actual</label>
											</div>
											
                                		</div>
                            		</div>
                        		</div>
                    		</div>
						</div>
                    </mat-tab>
                    
                    <!-- TAB COMPARATIVAS Y RANKING -->
                    <mat-tab  label="Comparativas y Ranking"> 
                         
                    </mat-tab> 
                </mat-tab-group>
            </div>
        </div>
    </div>
    
</div>
    



