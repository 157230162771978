import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileValidators } from 'ngx-file-drag-drop';
import { DistributionsService } from 'src/app/services/distributions.service';
import { MainService } from 'src/app/services/main.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-distribution-upload-ordered-guides',
  templateUrl: './distribution-upload-ordered-guides.component.html',
  styleUrls: ['./distribution-upload-ordered-guides.component.scss']
})
export class DistributionUploadOrderedGuidesComponent {
  bodyForm : FormData = new FormData(); 

  form!: FormGroup;
  fileControl = new FormControl([],[FileValidators.uniqueFileNames, FileValidators.maxFileCount(1)]);
  campaign : any;
  validated: boolean = false;
  isClientUsr: any;
  responsedata : any;
  res_errors: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private distService : DistributionsService,
    private main : MainService,
    private fb : FormBuilder,
    private dialogRef: MatDialogRef<DistributionUploadOrderedGuidesComponent>,
  ) { 
    this.campaign = this.data.campaign;
  }

  ngOnInit(): void {
    this. preloadFormData();
    this.loadForm();
  }

  preloadFormData(){
    this.bodyForm.append('id_campana', this.campaign.id);
  }
  
  loadForm() {
    this.form = this.fb.group({
      blob : [null, {validators:[Validators.required]}]
    });
  }
  
  onValueChange(files : any)
  { 
    this.res_errors = null;
    if(files.length >= 2)
    {      
      files.splice(1, 1);
      Swal.fire({
        icon: 'warning',
        title: 'Error',
        html: 'Sólo puede adjuntarse un documento'
      });
    }

    for (const item of files) {
      if(!item.name.endsWith('.xls')&&!item.name.endsWith('.xlsx'))
      {
        const index = files.indexOf(item);
  
        Swal.fire({
          icon: 'warning',
          title: 'Error',
          html: 'Solo se permiten documentos en formato Excel'
        });
    
        if (index >= 0) {
          files.splice(index, 1);
        }
      }
      else {
        this.form.value.blob = files;
      }
    }
  }
  
  uploadFile(){
    console.log(this.form.value);
    if(this.form.value.blob != null && this.form.value.blob?.length!=0){
        this.bodyForm.append("blob", this.form.value.blob[0] as File);
      
      Swal.fire({
        icon: 'info',
        title: 'Cargando archivo...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      this.distService.uploadOrderedGuides(this.bodyForm).subscribe(
        (result:any)=>{
          if(result.message == 'Success'){
            Swal.fire({
              icon: 'success',
              title: 'Archivo cargado',
              text: 'Archivo cargado exitosamente'          
            }).then(() => {            
              this.dialogRef.close({ data: result.data });
            });
          }
          else {
            this.res_errors = result.errors;
            Swal.fire({
              icon: 'error',
              title: 'Error al guardar'
            });
          }
        },
        (err:any)=>{
          this.main.HideLoading();
          if(err.error.message == "Invalid file") {
            Swal.fire({
              icon: 'warning',
              title: 'Error',
              html: 'Solo se permiten documentos en formato Excel'
            });
      
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Error al guardar',
              text: 'Ocurrió un error al guardar el archivo, por favor vuelve a intentarlo.'
            });
          }
        }
      )
      
  }else{
      Swal.fire({
        icon: 'warning',
        title: 'Error',
        html: 'El documento no ha sido adjuntado'
      });
    }
  }
}
