import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DistributionsService } from 'src/app/services/distributions.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-distribution-edit-shipment-data',
  templateUrl: './distribution-edit-shipment-data.component.html',
  styleUrls: ['./distribution-edit-shipment-data.component.scss']
})
export class DistributionEditShipmentDataComponent {
  formSh!: FormGroup;

  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private distS: DistributionsService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<DistributionEditShipmentDataComponent>,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.createFormSh();
    this.setShForm();
  }
  
  createFormSh() {
    this.formSh = this.fb.group({
      street: ['', [ Validators.required]],
      number: ['', [Validators.required]],
      suburb: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      postalCode: ['', [Validators.required, Validators.min(10000)]],
      reference: ['', [Validators.required]],
      providers: [''],
      carriers: ['', [Validators.required]],
      service: [''],
      weight: ['', [Validators.required, Validators.min(1)]],
      length: ['', [Validators.required, Validators.min(1)]],
      width: ['', [Validators.required, Validators.min(1)]],
      height: ['', [Validators.required, Validators.min(1)]],
      content: ['', [Validators.required]],
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
    });
  }

  setShForm() {
    const shData = { 
      street: this.data.shipment.street, 
      number: this.data.shipment.number, 
      suburb: this.data.shipment.suburb, 
      city: this.data.shipment.city, 
      state: this.data.shipment.state, 
      postalCode: this.data.shipment.postalCode, 
      reference: this.data.shipment.reference, 
      providers: this.data.shipment.providers, 
      carriers: this.data.shipment.carriers, 
      service: this.data.shipment.service, 
      weight: this.data.shipment.weight, 
      length: this.data.shipment.length, 
      width: this.data.shipment.width, 
      height: this.data.shipment.height,
      content: this.data.shipment.content,
      name: this.data.shipment.name,
      email: this.data.shipment.email,
      phone: this.data.shipment.phone
    };
    this.formSh.setValue(shData);
  }
  
  ruSure() {
    if(this.formSh.invalid) { return; }
    Swal.fire({
      title: '¿Estás seguro que deseas editar el registro?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        this.saveData();
      }
    });
  }

  
  saveData() {
    Swal.fire({
      icon: 'info',
      title: 'Guardando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    this.formSh.value.id_campana = this.data.id_campana;
    this.formSh.value.id_distribucion = this.data.shipment.id;
    this.distS.editDistShData(this.formSh.value).subscribe(
      (result:any) => {
        if(result.message == "Success") {
          Swal.fire({
            icon: 'success',
            title: 'Guardado',
            html: 'Usuario guardado de manera correcta!'
          }).then(() => {            
            this.dialogRef.close({ reload: true });
          });
        }
      },
      (err:any) => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Error al guardar',
          text: 'Ocurrió un error al guardar el registro, por favor vuelva a intentarlo.'
        });
      }
    );
  }
}
