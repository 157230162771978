<div class="modal-header">
    <div class="container">
        <div class="row col-md-12">
            <h5 class="pt-4 col-md-12 d-flex justify-content-between align-items-center">
                <span class="ms-4 col-md-8">Periodos para el dia: <strong>{{data.fecha | date: 'dd/MM/yyy'}}</strong> </span>
                <button type="button" class="btn-close" mat-dialog-close></button>
            </h5>
        </div>
    </div>
</div>

<mat-dialog-content class="mat-typography">
    <div class="modal-body">
        <div class="container">

            <div class="mat-elevation-z0 table-responsive text-nowrap">
                <table mat-table [dataSource]="dsPeriodos" class="w-100" id="tablePeriodos">
                    <ng-container matColumnDef="hora_inicio">
                        <th mat-header-cell *matHeaderCellDef class="text-center"> Hora Inicio </th>
                        <td mat-cell *matCellDef="let row" class="text-center">
                            <!-- <mat-form-field class="input-form-field mb-1">
                                <input matInput [ngxMatTimepicker]="pickerInicio" [(ngModel)]="row.hora_inicio" placeholder="Selecciona">
                                <ngx-mat-timepicker-toggle matSuffix [for]="pickerInicio"></ngx-mat-timepicker-toggle>
                                <ngx-mat-timepicker #pickerInicio></ngx-mat-timepicker>
                            </mat-form-field> -->
                            <mat-form-field class="input-form-field mb-1 optionHora">
                                <mat-label>Selecciona una hora</mat-label>
                                <mat-select [(ngModel)]="row.hora_inicio" (selectionChange)="validarHoraInicio(row)">
                                  <mat-option *ngFor="let hora of horas" [value]="hora">{{ hora }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="hora_fin">
                        <th mat-header-cell *matHeaderCellDef class="text-center"> Hora Fin </th>
                        <td mat-cell *matCellDef="let row" class="text-center">
                            <mat-form-field class="input-form-field mb-1 optionHora">
                                <mat-label>Selecciona una hora</mat-label>
                                <mat-select [(ngModel)]="row.hora_fin" (selectionChange)="validarHoraFin(row)">
                                  <mat-option *ngFor="let hora of horas" [value]="hora">{{ hora }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="cajas">
                        <th mat-header-cell *matHeaderCellDef class="text-center"> Cajas a encintar </th>
                        <td mat-cell *matCellDef="let row" class="text-center">
                            <mat-form-field class="input-form-field mb-1">
                                <input matInput type="number" placeholder="Ingresa" [(ngModel)]="row.cajas" class="text-center" [min]="0" (keypress)="validateInput($event)">
                            </mat-form-field>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="eliminar">
                        <th mat-header-cell *matHeaderCellDef class="text-center"> Eliminar </th>
                        <td mat-cell *matCellDef="let row" class="text-center">
                            <button mat-icon-button color="warn" class="mb-1" (click)="eliminarFila(row)"><mat-icon>delete</mat-icon></button>
                        </td>
                    </ng-container>                                         
                    
                    
                    <tr mat-header-row *matHeaderRowDef="colsPeriodos"></tr>
                    <tr mat-row *matRowDef="let row; columns: colsPeriodos;"></tr>
        
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell text-center" colspan="4">No se han añadido periodos para esta fecha</td>
                    </tr>
                </table>
            </div>

           
        </div>
    </div>
    <div class="modal-footer mt-5 d-flex justify-content-between align-items-center">
        <span class="ms-3 me-auto">Objetivo de cajas a encintar: {{data.cajas}}</span>
        <div>
            <button mat-flat-button color="accent" (click)="addPeriodo()"><mat-icon>add</mat-icon>Añadir</button>
            <button mat-flat-button class="bg-success text-white ms-3" type="button" (click)="validaPeriodos(data.idObjetivo,data.campana)">Guardar <mat-icon>save</mat-icon></button>
        </div>
    </div>
</mat-dialog-content>



