import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BoxModelPNP } from 'src/app/models/campaigns.model';
import { DistributionsService } from 'src/app/services/distributions.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mf-add',
  templateUrl: './mf-add.component.html',
  styleUrls: ['./mf-add.component.scss']
})
export class MfAddComponent {
  
  isLoading = false;
  formMf!: FormGroup;
  carriersList : any;
  id_campana!: number;
  boxListPNP = [];
  showTable: boolean = false;
  displayedColumns: string[] = ['id', 'consecutivo', 'zona', 'sucursal'];
  // displayedColumns: string[] = ['id', 'consecutivo', 'zona', 'sucursal', 'status'];
  dataSource!: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private fb: FormBuilder,
    private distService : DistributionsService,
    private dialogRef: MatDialogRef<MfAddComponent>
  ){
    this.id_campana = this.data.id_campana;
  }

  ngOnInit(): void {
    this.createFormMf();
    this.getListCarriers();
  }

  getListCarriers(){
    this.distService.getCarriers(this.id_campana).subscribe(
      (result:any) => {
        if(result.message == "Success") {
          this.carriersList = result.data;
          if(this.carriersList.length == 1){
            const f_carrier = this.formMf.get('paqueteria');
            if(f_carrier) f_carrier.setValue(this.carriersList[0]);
          }
        }
      },
      (err:any) => {
        console.log(err);
      }
    );
  }

  createFormMf(){
    this.formMf = this.fb.group({
      nombre: ['', [ Validators.required]],
      paqueteria: ['', [ Validators.required]],
      empresa: ['', [ Validators.required]],
      recolector: ['', [ Validators.required]]
    });
  }

  getBoxsListPNP(event: any , carrier: string){
    this.boxListPNP = [];
    this.isLoading = true;
    
    if(event.source.selected&&carrier) {
      this.distService.getListBoxMf(this.id_campana, carrier).subscribe(
        (result: any) => {
          this.boxListPNP = result.data.map((e:any)=>e.id);
          if(this.boxListPNP.length == 0) {
            Swal.fire({
              title: 'No hay cajas finalizadas para esa paquetería',
              icon: 'warning',
              showConfirmButton: true
            }).then(() => {
              const f_carrier = this.formMf.get('paqueteria');
              if(f_carrier) f_carrier.setValue(0);
            });
            this.showTable = false;
          }
          else {
            this.showTable = true;
            this.dataSource = new MatTableDataSource(result.data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        },
        (err: any) => {
          console.log(err);
          this.isLoading = false;
        }
      );
    }
    this.isLoading = false;
  }

  ruSure() {
    if(this.formMf.invalid) { return; }
    if(this.boxListPNP.length > 0) {
      Swal.fire({
        title: '¿Estás seguro que deseas guardar el registro?',
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`
      }).then((result) => {      
        if (result.isConfirmed) {
          this.saveMf();
        }
      });
    }
  }

  saveMf() {
    Swal.fire({
      icon: 'info',
      title: 'Guardando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    this.formMf.value.box_list = this.boxListPNP;
    this.formMf.value.id_campana = this.id_campana;
    this.distService.saveMf(this.formMf.value).subscribe(
      (result:any) => {
        if(result.message == "Success") {
          Swal.fire({
            icon: 'success',
            title: 'Guardado',
            text: 'Manifiesto generado de manera correcta!'       
          }).then(() => {            
            this.dialogRef.close({ reload: true });
          });
        }
      },
      (err:any) => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Error al guardar',
          text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
        });
      }
    );
  }

}
