<div class="modal-header">
    <h5 class="pt-4 ps-4">Nuevo Manifiesto</h5>
    <button type="button" class="btn-close me-4" mat-dialog-close></button>
</div>
<mat-dialog-content class="mat-typography">
    <div class="modal-body">
        <span>Captura la siguiente información:</span>
        <form autocomplete="off" [formGroup]="formMf" (ngSubmit)="ruSure()" class="mt-3" id="userForm">
            <small>Campo obligatorio *</small>
            <div class="row mt-2">
                <div class="col-sm-6">
                    <mat-form-field class="w-100" color="accent">
                        <mat-label>Nombre de manifiesto</mat-label>
                        <input matInput formControlName="nombre">
                    </mat-form-field>
                    <mat-form-field class="w-100" color="accent">
                        <mat-label>Empresa de recolección</mat-label>
                        <input matInput formControlName="empresa">
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="w-100" color="accent">
                        <mat-label>Paquetería</mat-label>
                        <mat-select formControlName="paqueteria">
                            <mat-option (onSelectionChange)="getBoxsListPNP($event,p)" *ngFor="let p of carriersList" [value]="p">{{p}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-100" color="accent">
                        <mat-label>Nombre de recolector</mat-label>
                        <input matInput formControlName="recolector">
                    </mat-form-field>
                </div>
            </div>
        </form>

        <div [hidden]="!showTable" class="mat-elevation-z8 table-responsive">
            <table mat-table [dataSource]="dataSource" matSort class="w-100">
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header matSortStart="asc"> Box ID </th>
                    <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <ng-container matColumnDef="consecutivo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header matSortStart="asc"> Consecutivo </th>
                    <td mat-cell *matCellDef="let row"> {{row.consecutivo}} </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <ng-container matColumnDef="zona">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header matSortStart="asc"> Zona </th>
                    <td mat-cell *matCellDef="let row"> {{row.zona}} </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <ng-container matColumnDef="sucursal">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header matSortStart="asc"> Sucursal </th>
                    <td mat-cell *matCellDef="let row"> {{row.sucursal}} </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                
                <!-- <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header matSortStart="asc"> Estatus </th>
                    <td mat-cell *matCellDef="let row"> Caja cerrada </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container> -->

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="6">No se encontraron cajas abiertas para esta paquetería.</td>
                </tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
            </table>

            <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                <mat-progress-spinner color="primary" mode="indeterminate">
                </mat-progress-spinner>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button class="bg-success text-white" form="userForm" type="submit">Guardar <mat-icon>save</mat-icon></button>
</mat-dialog-actions>