<div class="container-fluid">
    <div class="page-title">
        <div class="row">
            <div class="col-6">
                <h3><mat-icon class="align-middle">inventory</mat-icon> Encintado</h3>
            </div>
            <div class="col-6">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="index.html"><mat-icon color="accent">inventory</mat-icon></a></li>
                    <li class="breadcrumb-item">Encintado</li>
                </ol>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row">
        <div class="col-8">
            <div *ngFor="let element of campaignListPNP" class="card">
                <div class="card-header">
                    <div class="header-top">
                        <h4>Resumen campaña {{element.company}} {{element.name }}</h4>
                        <div class="card-header-right-icon">
                            <div>

                            <button class="me-4" color="accent" mat-button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu">
                                {{selectedCampaign.company}} {{selectedCampaign.nombre}} 
                                <mat-icon>expand_more</mat-icon>
                            </button>

                            <mat-menu #menu="matMenu">
                                <div>
                                    <div>
                                        <button (click)="setCampaign(element)" *ngFor="let element of tapingCampaignList"  mat-menu-item>
                                            {{element.company}} {{ element.nombre }}
                                        </button>
                                    </div>
                                </div>
                            </mat-menu>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row justify-content-between">
                        <div *ngFor="let e of element.progress_by_carrier" class="chart row justify-content-between">
                            <div class="col">
                                <img 
                                *ngIf="isImageAvailable(imgFormat(e.carrier))" 
                                class="mt-2 img-fluid img-60 progress-chart-image" 
                                [src]="'../../../assets/images/carriers/' + imgFormat(e.carrier) + '-sm.jpg'" 
                                [alt]="imgFormat(e.carrier)">
                                <span 
                                    *ngIf="!isImageAvailable(imgFormat(e.carrier))" 
                                    class="mt-2 carrier-name">{{ e.carrier }}</span>
                                </div>
                            <div class="col text-end">
                                <span class="text-muted">({{e.encintados}} de {{e.total}}) - </span>
                                <span class="text-muted">{{ (e.encintados / e.total) * 100 | number: '1.0-2' }}%</span>
                            </div>
                                <app-progress-bar-chart [data]="(e.encintados/e.total)*100" [carrier]="e.carrier" ></app-progress-bar-chart>
                        </div>
                    </div>

                    <!-- <div *ngFor="let element of campaignListPNP" class="row justify-content-between">
                        <div class="col">
                            <img class="img-fluid img-60 progress-chart-image" src="../../../assets/images/carriers/{{imgFormat(element.progress_by_carrier[0].carrier)}}-sm.jpg" alt="{{imgFormat(element.progress_by_carrier[0].carrier)}}">
                        </div>
                        <div class="col text-end">
                            <span class="text-muted">{{element.progress_by_carrier[0].total | number:'1.0-2'}}%</span>
                        </div>
                        <app-progress-bar-chart [data]="element.progress_by_carrier[0].encintados" ></app-progress-bar-chart>
                        </div> -->

    
                    
                    <!-- <div class="row justify-content-between mt-5">
                        <div class="col">
                            <h6>POP FAB FEBRERO 25</h6>
                        </div>
                        <div class="col text-end">
                            <span class="text-muted">24%</span>
                            </div>
                            <app-progress-bar-chart [data]="24" ></app-progress-bar-chart>
                    </div>
                    <div class="row justify-content-between mt-5">
                        <div class="col">
                            <h6>POP FAB ENERO 25</h6>
                        </div>
                        <div class="col text-end">
                            <span class="text-muted">54%</span>
                            </div>
                            <app-progress-bar-chart [data]="54" ></app-progress-bar-chart>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="card">
                <div class="card-header">
                    <h4>Ultimo escaneo</h4>
                </div>
                <div class="card-body">

                    <div>
                        <mat-spinner *ngIf="isLoading; else distData"></mat-spinner>
                    </div>
                    <ng-template #distData>
                        <div *ngIf="!boxScanned;else box" class="row">
                            <div class="col-4 d-flex justify-content-center align-items-center">
                                <img style="width: 100px;" src="./../../../../assets/images/start.png" alt="Start">
                            </div>
                            <div class="col-8 d-flex align-items-center">
                                <span style="max-width: 200px;">Para comenzar, escanea el código de la caja.</span>
                            </div>
                        </div>
                        
                        <ng-template #box>
                            <div class="row justify-content-between">
                                <div class="col-9">
                                    <h4>{{boxScanned}}</h4>
                                    <div *ngIf="boxData">
                                        <span class="d-block">Campaña: {{boxData.campana}}</span>
                                        <span class="d-block">Sucursal: {{boxData.nombre}}</span>
                                        <span class="d-block">Consecutivo: {{boxData.consecutivo}}</span>
                                    </div>
                                </div>
                                <div *ngIf="boxData" class="col-3">
                                    <img 
                                        *ngIf="isImageAvailable(imgFormat(boxData.carriers))" 
                                        class="mt-2 img-fluid img-60 progress-chart-image" 
                                        [src]="'../../../assets/images/carriers/' + imgFormat(boxData.carriers) + '-sm.jpg'" 
                                        [alt]="imgFormat(boxData.carriers)">
                                        <span 
                                            *ngIf="!isImageAvailable(imgFormat(boxData.carriers))" 
                                            class="mt-2 carrier-name">{{ boxData.carriers }}</span>
                                    <!-- <img *ngIf="boxData" class="mt-2 img-fluid " src="../../../assets/images/carriers/{{imgFormat(boxData.carriers)}}-sm.jpg" alt="{{imgFormat(boxData.carriers)}}"> -->
                                </div>
                            </div>
                        </ng-template>
                    </ng-template>
                </div>
            </div>
            <div class="card">
                <div class="card-header">
                    <h4>Escaneos recientes</h4>
                </div>
                <div class="card-body">
                    <div *ngFor="let element of logs" class="row justify-content-between mt-2">
                        <div class="col">
                            <span class="d-block">{{element.id_distribucion}}</span>
                        </div>
                        <div class="col text-end">
                            <span class="d-block">{{ formatTime(element.created_at, 6) | date: 'HH:mm:ss' }}</span>
                        </div>
                        <div class="col text-end">
                            <span class="{{element.status === 'success' ?  'badge text-bg-success' : element.status === 'info' ? 'badge text-bg-info' : 'badge text-bg-danger'}}">{{ element.status === "success" ? "Encintado" : element.status === "info" ? "Info" : "Error" }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>