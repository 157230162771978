export class CampaignModel {
    id!: number;
    name!: string;
    created_at!: string;
    file!: string;
    file_name!: string;
    active!: number;
    id_company!: number;
    id_nest!: number;
}

export class FileModel {
    id!: string;
    name!: string;
    created_at!: string;
}

export class PendingBoxes {
    zona!:         string;
    sucursal!:     string;
    paqueteria!:   string;
    guia!:         string;
    estatus!:      string;
    lastUpdate!:  string;
}

export class CampaignTrackingModel {
    guia!: string;
    fecha_entrega!: string;
    recibio!: string;
    estatus!: string;
    last_update!: string;
    zona!: string;
    sucursal!: string;
    direccion!: string;
    coords!: string;
}

export class BranchModel {
    id!: number;
    id_compania!: number;
    compania!: string;
    id_zona!: number;
    zona!: string;
    nombre!: string;
    filial!: string;
    farm_id!: string;
    estafeta!: string;
    shipping_type!: number;
}

export class CampaignModelPNP {
    id!: number;
    name!: string;
    created_at!: string;
    total_boxes!: number;
    start_boxes!: number;
    ended_boxes!: number;
    rest_boxes!: number;
    materials!: number;
    taped_perc!:number;
    status!: string;
}

export class BoxModelPNP {
    id!: number;
    consecutivo!: number;
    zona!: string;
    sucursal!: string;
    entro_linea!: string;
    video!: string;
}

export class MaterialsModelPNP {
    id!: number;
    nombre!: string;
    cantidad!: number;
    sku!: string;
    packed!: number;
    omitted!: number;
    missed!: number;
}