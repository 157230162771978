<div class="container-fluid">
    <div class="page-title">
        <div class="row">
            <div class="col-6">
                <h3><mat-icon class="align-middle">campaign</mat-icon> {{ campana?campana.name:"" }}</h3>
            </div>
            <div class="col-6">
                <div class="float-end">
                    <button *ngIf="campana&&campana.file_dist&&!campana.file_bg" mat-flat-button color="primary" (click)="downloadFileDistData()" class="mt-2 mb-2 ms-4 text-white"> 
                        Descargar Sucursales y BG's <mat-icon>download_file</mat-icon>
                    </button>
                    <a *ngIf="campana&&campana.file_bg" mat-raised-button color="primary" [href]="campana.file_bg" target="_blank" class="mt-2 mb-2 ms-4 text-white">
                        Descargar Sucursales y BG's <mat-icon>download_file</mat-icon>
                    </a>
                    
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <mat-tab-group #tabGroup mat-stretch-tabs="false" mat-align-tabs="start" (selectedTabChange)="onTabChanged($event);">
                        <mat-tab><!-- cajas -->
                            <ng-template mat-tab-label>
                                <mat-icon class="me-2 align-middle">inventory</mat-icon> Cajas
                            </ng-template>
                            <mat-divider></mat-divider>
                            <div class="overflow-auto w-100 mt-2">
                                <mat-form-field color="accent">
                                    <mat-label>
                                        <mat-icon>search</mat-icon> Buscar</mat-label>
                                    <input matInput (keyup)="applyFilter($event, dsSucursales)" placeholder="Ej. mail@mail.com" #input>
                                </mat-form-field>
                                <button  mat-flat-button color="primary" (click)="openUploadDist()" class="mt-2 mb-2 ms-4 text-white float-end"> 
                                    Cargar distribución <mat-icon>upload_file</mat-icon>
                                </button>
                                <button *ngIf="campana&&campana.file" mat-flat-button color="primary" (click)="openDialogCloseBox()" class="mt-2 mb-2 ms-4 text-white float-end"> 
                                    Cerrar cajas <mat-icon>inventory </mat-icon>
                                </button>
                            </div>
                            <div class="mat-elevation-z0 table-responsive text-nowrap">
                                <table mat-table [dataSource]="dsSucursales" matSort #sort="matSort" class="w-100">
                                    <ng-container matColumnDef="box_id">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                                        <td mat-cell *matCellDef="let row"> {{row.box_id}} </td>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="consecutivo">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Consecutivo </th>
                                        <td mat-cell *matCellDef="let row"> {{row.consecutivo}} </td>
                                    </ng-container>                                    
                        
                                    <ng-container matColumnDef="id_ext">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> FarmID </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.id_ext }} </td>
                                    </ng-container>
                        
                                    <ng-container matColumnDef="filial">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Filial </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.filial }} </td>
                                    </ng-container>
                        
                                    <ng-container matColumnDef="zona">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Zona </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.zona}} </td>
                                    </ng-container>
                        
                                    <ng-container matColumnDef="sucursal">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Sucursal </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.sucursal }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Estatus línea </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.status }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="actions">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions </th>
                                        <td mat-cell *matCellDef="let row"> 
                                            <button mat-icon-button matTooltip="Listado de materiales" [matTooltipPosition]="'above'" color="accent" (click)="openDialogMaterialList(row)">
                                                <mat-icon>category</mat-icon> 
                                            </button> 
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="colsSucursales"></tr>
                                    <tr mat-row *matRowDef="let row; columns: colsSucursales;"></tr>
                        
                                    <tr class="mat-row" *matNoDataRow>
                                        <td class="mat-cell" colspan="6">No se a cargado la Distribución.</td>
                                    </tr>
                                </table>
                                <mat-paginator #paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                            </div>
                        </mat-tab>
                        <mat-tab><!-- bolsas -->
                            <ng-template mat-tab-label>
                                <mat-icon class="me-2 align-middle">local_mall</mat-icon> Bolsas
                            </ng-template>
                            <div class="card mb-4">
                                <div class="card-body">
                                    <form #f="ngForm">
                                        <div class="overflow-auto w-200">
                                            <mat-form-field>
                                                <mat-label>
                                                    <mat-icon>search</mat-icon> Buscar</mat-label>
                                                <input matInput (keyup)="applyFilter($event,dsBGs)" placeholder="Sobre burbuja" #input>
                                            </mat-form-field>
                                        </div>
                                        <div class="mat-elevation-z0 table-responsive text-nowrap">
                                            <table mat-table [dataSource]="dsBGs" matSort #sortBG="matSort" class="w-100">                                        
                                                <ng-container matColumnDef="bag_id">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> bag_id </th>
                                                    <td mat-cell *matCellDef="let row"> {{ row.bag_id }} </td>
                                                </ng-container>

                                                <ng-container matColumnDef="BG">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> BG </th>
                                                    <td mat-cell *matCellDef="let row"> {{ row.sku }} </td>
                                                </ng-container>

                                                <ng-container matColumnDef="cantidad">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad de BG </th>
                                                    <td mat-cell *matCellDef="let row"> {{ row.cantidad }} </td>
                                                </ng-container>

                                                <ng-container matColumnDef="cantidad_sku">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Cantidad de SKU en cada BG </th>
                                                    <td mat-cell *matCellDef="let row"> {{ row.cantidad_sku }} </td>
                                                </ng-container>

                                                <ng-container matColumnDef="cantidad_pza">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad de piezas en cada BG </th>
                                                    <td mat-cell *matCellDef="let row"> {{ row.cantidad_pza }} </td>
                                                </ng-container>

                                                <ng-container matColumnDef="available">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Disponible </th>
                                                    <td mat-cell *matCellDef="let row"> 
                                                        <mat-label hidden>{{row.status_txt}}</mat-label>
                                                        <button mat-icon-button [matMenuTriggerFor]="menuStatus" [matTooltip]="'Cambiar estatus: ' + (row.status==3?'Retirar de linea':(row.status==2?'Faltante':'En existencia'))" [matTooltipPosition]="'above'" [color]="row.status==2||row.status==3?'warn':'primary'">
                                                            <mat-icon>{{row.status==2?"warning":(row.status==3?"cancel":"check_circle")}}</mat-icon>
                                                        </button>
                                                        <mat-menu #menuStatus="matMenu">
                                                            <button [hidden]="row.status==1" mat-menu-item matTooltip="Retira alertas y marcas en material" (click)="changeMatStatus(row, 1, 'bg')" [matTooltipPosition]="'above'">
                                                                <mat-icon>check_circle</mat-icon>
                                                                <span>En existencia</span>
                                                            </button>
                                                            <button [hidden]="row.status==2" mat-menu-item matTooltip="Agrega una marca en material visible en PNP" (click)="changeMatStatus(row, 2, 'bg')" [matTooltipPosition]="'above'">
                                                                <mat-icon>warning</mat-icon>
                                                                <span>Faltante</span>
                                                            </button>
                                                            <button [hidden]="row.status==3" mat-menu-item matTooltip="Alerta y solicita retirar caja que contiene este material" (click)="changeMatStatus(row, 3, 'bg')" [matTooltipPosition]="'above'">
                                                                <mat-icon>cancel</mat-icon>
                                                                <span>Retirar de linea</span>
                                                            </button>
                                                        </mat-menu> 
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="actions">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Materiales </th>
                                                    <td mat-cell *matCellDef="let row"> 
                                                        <button mat-icon-button matTooltip="Listado de materiales" [matTooltipPosition]="'above'" color="accent" (click)="openDialogBGMaterialList(row)">
                                                            <mat-icon>category</mat-icon> 
                                                        </button> 
                                                    </td>
                                                </ng-container>
                                    
                                                <tr mat-header-row *matHeaderRowDef="colsBGs"></tr>
                                                <tr mat-row *matRowDef="let row; columns: colsBGs;"></tr>
                                    
                                                <tr class="mat-row" *matNoDataRow>
                                                    <td class="mat-cell" colspan="6">No se requieren BG's</td>
                                                </tr>
                                            </table>
                                            <mat-paginator #paginatorBG="matPaginator" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                                        </div>   
                                    </form>   
                                </div>                                
                            </div>
                        </mat-tab>
                        <mat-tab><!-- acomodo -->
                            <ng-template mat-tab-label>
                                <mat-icon class="me-2 align-middle">shelves</mat-icon> Acomodo Racks
                            </ng-template>
                            <mat-divider></mat-divider>
                            <div class="overflow-auto w-100 mt-2">
                                <mat-form-field color="accent">
                                    <mat-label>
                                        <mat-icon>search</mat-icon> Buscar</mat-label>
                                    <input matInput (keyup)="applyFilter($event, dsRacks)" placeholder="Ej. mail@mail.com" #input>
                                </mat-form-field>
                                <button *ngIf="campana&&campana.file_dist" mat-flat-button color="primary" (click)="openUploadRackDist()" class="mt-2 mb-2 ms-4 text-white float-end"> 
                                    Cargar acomodo <mat-icon>upload_file</mat-icon>
                                </button>
                            </div>
                            <div class="mat-elevation-z0 table-responsive text-nowrap">
                                <table mat-table [dataSource]="dsRacks" matSort #sortRK="matSort" class="w-100">
                                    <ng-container matColumnDef="nombre">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Estación </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.nombre }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="line">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Línea </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.line }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="tipo">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.tipo }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="actions">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Materiales </th>
                                        <td mat-cell *matCellDef="let row"> 
                                            <button mat-icon-button matTooltip="Listado de materiales" [matTooltipPosition]="'above'" color="accent" (click)="openDialogRackMaterialList(row)">
                                                <mat-icon>category</mat-icon> 
                                            </button> 
                                        </td>
                                    </ng-container>
                        
                                    <tr mat-header-row *matHeaderRowDef="colsRacks"></tr>
                                    <tr mat-row *matRowDef="let row; columns: colsRacks;"></tr>
                                    <tr class="mat-row" *matNoDataRow>
                                        <td class="mat-cell">Aun no se carga ningun acomodo. </td>
                                    </tr>
                                </table>
                                <mat-paginator #paginatorRK="matPaginator" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                            </div>
                        </mat-tab>
                        <mat-tab><!-- materiales -->
                            <ng-template mat-tab-label>
                                <mat-icon class="me-2 align-middle">category</mat-icon> Materiales
                            </ng-template>
                            <mat-divider></mat-divider>
                            <div class="overflow-auto w-100 mt-2">
                                <mat-form-field color="accent">
                                    <mat-label>
                                        <mat-icon>search</mat-icon> Buscar</mat-label>
                                    <input matInput (keyup)="applyFilter($event, dsMaterials)" placeholder="Ej. mail@mail.com" #input>
                                </mat-form-field>
                                <button mat-flat-button (click)="openUploadMaterialImgs()" color="primary" class="mt-2 mb-2 ms-4 text-white float-end"> 
                                    Cargar imágenes
                                </button>
                            </div>
                            <div class="mat-elevation-z0 table-responsive text-nowrap">
                                <table mat-table [dataSource]="dsMaterials" matSort #sortMat="matSort" class="w-100">
                                    <ng-container matColumnDef="sku">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> SKU </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.sku }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="nombre">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.nombre }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="tipo">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tamaño </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.tipo=='g'?'Grande':(row.tipo=='c'?'Chico':'Bolsa') }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="cantidad">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.cantidad }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Disponible </th>
                                        <td mat-cell *matCellDef="let row"> 
                                            <mat-label hidden>{{row.status_txt}}</mat-label>
                                            <button [disabled]="row.tipo!='g'" mat-icon-button [matMenuTriggerFor]="menuStatus" [matTooltip]="'Cambiar estatus: ' + (row.status==3?'Retirar de linea':(row.status==2?'Faltante':'En existencia'))" [matTooltipPosition]="'above'" [color]="row.status==2||row.status==3?'warn':'primary'">
                                                <mat-icon>{{row.status==2?"warning":(row.status==3?"cancel":"check_circle")}}</mat-icon>
                                            </button>
                                            <mat-menu #menuStatus="matMenu">
                                                <button [hidden]="row.status==1" mat-menu-item matTooltip="Retira alertas y marcas en material" (click)="changeMatStatus(row, 1, 'mat')" [matTooltipPosition]="'above'">
                                                    <mat-icon>check_circle</mat-icon>
                                                    <span>En existencia</span>
                                                </button>
                                                <button [hidden]="row.status==2" mat-menu-item matTooltip="Agrega una marca en material visible en PNP" (click)="changeMatStatus(row, 2, 'mat')" [matTooltipPosition]="'above'">
                                                    <mat-icon>warning</mat-icon>
                                                    <span>Faltante</span>
                                                </button>
                                                <button [hidden]="row.status==3" mat-menu-item matTooltip="Alerta y solicita retirar caja que contiene este material" (click)="changeMatStatus(row, 3, 'mat')" [matTooltipPosition]="'above'">
                                                    <mat-icon>cancel</mat-icon>
                                                    <span>Retirar de linea</span>
                                                </button>
                                            </mat-menu> 
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="actions">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Imagenes </th>
                                        <td mat-cell *matCellDef="let row"> 
                                            <button *ngIf="row.imgs" (click)="openDialogMaterialIgs(row)" mat-icon-button matTooltip="Ver" [matTooltipPosition]="'above'" color="accent">
                                                <mat-icon>image</mat-icon>
                                            </button>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="colsMat"></tr>
                                    <tr mat-row *matRowDef="let row; columns: colsMat;"></tr>
                                    <tr class="mat-row" *matNoDataRow>
                                        <td class="mat-cell">Aun no se carga ningun material. </td>
                                    </tr>
                                </table>
                                <mat-paginator #paginatorMat="matPaginator" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                            </div>
                        </mat-tab>
                        <mat-tab><!-- manifiestos -->
                            <ng-template mat-tab-label>
                                <mat-icon class="me-2 align-middle">list</mat-icon> Manifiestos
                            </ng-template>
                            <mat-divider></mat-divider>
                            <div class="overflow-auto w-100 mt-2">
                                <mat-form-field color="accent">
                                    <mat-label>
                                        <mat-icon>search</mat-icon> Buscar</mat-label>
                                    <input matInput (keyup)="applyFilter($event, dsMf)" placeholder="Ej. Estafeta" #input>
                                </mat-form-field>
                                <button *ngIf="campana&&campana.file" mat-flat-button color="primary" (click)="openDialogSaveMf()" class="mt-2 mb-2 ms-4 text-white float-end"> 
                                    Crear Manifiesto <mat-icon>list </mat-icon>
                                </button>
                            </div>
                            <div class="mat-elevation-z0 table-responsive text-nowrap">
                                <table mat-table [dataSource]="dsMf" matSort #sortMf="matSort" class="w-100">
                                    <ng-container matColumnDef="nombre">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.nombre }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="paqueteria">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Paquetería </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.paqueteria }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="empresa">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Empresa </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.empresa }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="recolector">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Recolector </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.recolector }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="cantidad">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.cantidad }} </td>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="fecha_salida">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de salida </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.fecha_salida | date: 'dd/MM/yyyy hh:mma'}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="actions">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                                        <td mat-cell *matCellDef="let row"> 
                                            <button *ngIf="!row.documento" mat-icon-button matTooltip="Firmar" (click)="openDialogDocMf(row)" [matTooltipPosition]="'above'" color="accent">
                                                <mat-icon>gesture</mat-icon>
                                            </button>
                                            <a *ngIf="row.documento" mat-icon-button matTooltip="Firmar" target="_blank" [href]="row.documento" [matTooltipPosition]="'above'" color="accent">
                                                <mat-icon>download</mat-icon>
                                            </a>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="colsMf"></tr>
                                    <tr mat-row *matRowDef="let row; columns: colsMf;"></tr>
                                    <tr class="mat-row" *matNoDataRow>
                                        <td class="mat-cell">Aun no se crea ningun manifiesto. </td>
                                    </tr>
                                </table>
                                <mat-paginator #paginatorMf="matPaginator" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                            </div>
                        </mat-tab>
                        <mat-tab><!-- envíos -->
                            <ng-template mat-tab-label>
                                <mat-icon class="me-2 align-middle">local_shipping</mat-icon> Datos de envío
                            </ng-template>
                            <mat-divider></mat-divider>
                            <div class="overflow-auto w-100 mt-2">
                                <mat-form-field color="accent">
                                    <mat-label>
                                        <mat-icon>search</mat-icon> Buscar</mat-label>
                                    <input matInput (keyup)="applyFilter($event, dsSh)" placeholder="Ej. Estafeta" #input>
                                </mat-form-field>
                                <button  mat-flat-button color="primary" (click)="openUploadDistShipmentData()" class="mt-2 mb-2 ms-4 text-white float-end"> 
                                    Cargar datos de envio <mat-icon>local_shipping </mat-icon>
                                </button>
                                <button [hidden]="total_guias==0||isLoadingShipments||guias_compradas==(total_guias-total_locales)" mat-flat-button (click)="loadGetLabels()" color="primary" class="mt-2 mb-2 ms-4 text-white float-end"> 
                                    Comprar guias ({{guias_compradas}} de {{total_guias - total_locales}})
                                </button>
                                <button [hidden]="!isLoadingShipments" mat-flat-button (click)="stopGetLabels()" color="primary" class="mt-2 mb-2 ms-4 text-white float-end"> 
                                    Detener compra
                                </button>
                                <a *ngIf="campana&&campana.file_guias" mat-raised-button color="primary" [href]="campana.file_guias" target="_blank" class="mt-2 mb-2 ms-4 text-white float-end">
                                    Descargar guias <mat-icon>download_file</mat-icon>
                                </a>
                                <button *ngIf="campana&&campana.file_dist" mat-raised-button color="primary" (click)="openUploadOrderedGuides()" class="mt-2 mb-2 ms-4 text-white float-end">
                                    Generar guias ordenadas <mat-icon>add</mat-icon>
                                </button>
                            </div>
                            <div [hidden]="!isLoadingShipments">
                                <b>Guías procesadas {{guias_compradas}} de {{total_guias}} </b><b> - Errores: {{error_guias}}</b><b> - Envíos locales: {{total_locales}}</b>
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-accent" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" [style]="'width:' + progress + '%'">{{progress}}%</div>
                                </div>
                            </div>
                            <div class="mat-elevation-z8 table-responsive">
                                <table mat-table [dataSource]="dsSh" matSort #sortSh="matSort" class="w-100">
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Box_id </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.id }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Estatus </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.status?(row.status=="success"?"Comprada":(row.status=="in_progress"?"En Proceso":"Error")):(procesando.includes(row.id)&&isLoadingShipments?"En Proceso":"Pendiente") }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="consecutivo">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Consecutivo </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.consecutivo }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.name }} </td>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="address">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Dirección </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.street + " " +  row.number + " " + row.suburb + " " + row.city + " " + row.state + " " + row.postalCode}}  </td>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="reference">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Referencia </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.reference }} </td>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="provider">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Proveedor </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.providers }} </td>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="carrier">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Paquetería </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.carriers }} </td>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="service">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Servicio </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.service }} </td>
                                    </ng-container> 
                                    
                                    <ng-container matColumnDef="weight">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Peso </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.weight + "kg" }} </td>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="length">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Largo </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.length + "cm" }} </td>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="width">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Ancho </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.width + "cm" }} </td>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="height">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Alto </th>
                                        <td mat-cell *matCellDef="let row"> {{ row.height + "cm" }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="actions">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                                        <td mat-cell *matCellDef="let row"> 
                                            <button *ngIf="!row.label&&!isLoadingShipments" (click)="openEditDistShipmentData(row)" mat-icon-button matTooltip="Editar" [matTooltipPosition]="'above'" color="accent">
                                                <mat-icon>edit</mat-icon>
                                            </button>
                                            <mat-spinner [hidden]="row.label||!procesando.includes(row.id)||!isLoadingShipments" diameter="30" color="accent"></mat-spinner>
                                            <mat-icon color="accent" [hidden]="row.label||procesando.includes(row.id)||!isLoadingShipments" >schedule</mat-icon>
                                            <a *ngIf="row.label" mat-icon-button matTooltip="Descargar guía" target="_blank" [href]="row.label" [matTooltipPosition]="'above'" color="accent">
                                                <mat-icon>download</mat-icon>
                                            </a>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="colsSh"></tr>
                                    <tr mat-row *matRowDef="let row; columns: colsSh;"></tr>
                                    <tr class="mat-row" *matNoDataRow>
                                        <td class="mat-cell">Aun no se crea ningun manifiesto. </td>
                                    </tr>
                                </table>
                                <mat-paginator #paginatorSh="matPaginator" [pageSizeOptions]="[10, 25, 100, total_guias]"></mat-paginator>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </div>
</div>