import { Component, OnInit, ViewChild } from '@angular/core';
import { MonitoringService } from 'src/app/services/monitoring.service';
import { CampaignModel } from 'src/app/models/campaigns.model';

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss']
})
export class MonitoringComponent implements OnInit {

      campaignSelected?: CampaignModel;
      campaignList = new Array<CampaignModel>();
      isLoading = true;

      compania = '';
      fecha_inicio =  '';
      fecha_fin = '';

      //Objetivo general
      total_cajas: number = 0;
      total_cajas_encintadas: number = 0;
      duracion_campana: number = 0;
      objetivo_diario: number = 0;
      hr_restantes = '';
      progress: number = 0;

      //Cards
      totalSKU: number = 0;
      bolsas: number = 0;
      zonas: number = 0;
      totalPicks: number = 0;
      materialG: number = 0;
      sucursales: number = 0;

      periodos = [];

      constructor(
          private monitoringServ: MonitoringService
      ) {
        
      }

      ngOnInit(): void {
        this.getCampaignList();
      }

      //Obtener campaigns para el select
      getCampaignList() {
        this.monitoringServ.getCampaingsMonitoringList().subscribe(
          (result: any) => {
            this.campaignList = result;
            this.setCampaignData(this.campaignList[0]);
          },
          (err: any) => {
            console.log(err);
            this.isLoading = false;
          }
        );
      }

      setCampaignData (campaign:any) {
          this.isLoading = true;
          this.campaignSelected = campaign;
          this.getData(campaign);
          this.isLoading = false; 
      }

      getData(campaign: any) {
        this.monitoringServ.getMonitoringData(campaign.id).subscribe(
          (result: any) => {
            console.log("Result Data")
            console.log(result)

            //Encabezado
            this.compania = result.campaignData[0][0].clave;
     
            const fechaOriginal1 = new Date(result.campaignData[0][0].fecha_inicio);
            const fechaAjustada1 = new Date(fechaOriginal1.getTime() + (6 * 60 * 60 * 1000)); // Ajusta según zona horaria
            this.fecha_inicio = fechaAjustada1.toISOString().split('T')[0];

            const fechaOriginal2 = new Date(result.campaignData[0][0].fecha_fin);
            const fechaAjustada2 = new Date(fechaOriginal2.getTime() + (6 * 60 * 60 * 1000)); // Ajusta según zona horaria
            this.fecha_fin = fechaAjustada2.toISOString().split('T')[0];

            //Objetivo general
            this.total_cajas = result.boxes[0][0].cajas_por_encintar;
            this.duracion_campana = this.calcularDuracionCampana(this.fecha_inicio,this.fecha_fin);
            this.objetivo_diario = result.objetivoDiario[0][0]?.total_cajas || 0;
            this.hr_restantes = result.objetivoDiario[0][0]?.total_horas;
            this.total_cajas_encintadas = result.boxesTaped[0][0].cajas_encintadas;
            this.progress = (this.total_cajas_encintadas > 0 && this.objetivo_diario > 0) ? Math.round((this.total_cajas_encintadas / this.objetivo_diario) * 100) : 0;

            //Cards
            this.totalSKU = result.skus;
            this.bolsas = result.bags[0][0].bolsas;
            this.zonas = result.zones[0][0].zonas;
            this.totalPicks = result.picks;
            this.materialG = result.materialg;
            this.sucursales = result.sucursales[0][0].sucursales;

            //Periodos
            this.periodos = result.periodos[0];
            this.periodos = this.transformarPeriodos(this.periodos);

          },
          (err: any) => {
            console.log(err);
            this.isLoading = false;
          }
        );
      }

      calcularDuracionCampana(fecha1: any, fecha2: any) {
        // Crear objetos Date y normalizar a UTC sin horas/minutos
        const inicio = new Date(fecha1);
        const fin = new Date(fecha2);
        
        const inicioUTC = Date.UTC(inicio.getFullYear(), inicio.getMonth(), inicio.getDate());
        const finUTC = Date.UTC(fin.getFullYear(), fin.getMonth(), fin.getDate());
        
        // Calcular diferencia en días
        const diferenciaMs = Math.abs(finUTC - inicioUTC);
        return Math.floor(diferenciaMs / (1000 * 60 * 60 * 24)) + 1;
      }

      convertirHoraFormato12h(hora24: any) {
        const [hora, minutos] = hora24.split(':');
        const horaNumerica = parseInt(hora);
        const periodo = horaNumerica >= 12 ? 'pm' : 'am';
        const hora12 = horaNumerica % 12 || 12;
        return `${hora12}:${minutos} ${periodo}`;
      }

      transformarPeriodos(periodos: any) {
        return periodos.map((periodo: any, index: any) => {

          let nuevoNombre = '';
          if((index + 1) == 1 || (index + 1) == 3){
             nuevoNombre = `${index + 1}er periodo`;
          }
          else if((index + 1) == 2){
            nuevoNombre = `${index + 1}do periodo`;
          }
          else if((index + 1) == 4 || (index + 1) == 5 || (index + 1) == 6){
            nuevoNombre = `${index + 1}to periodo`;
          }

          const nuevaHoraInicio = this.convertirHoraFormato12h(periodo.hora_inicio);
          const nuevaHoraFin = this.convertirHoraFormato12h(periodo.hora_fin);
          
          return {
            nombre: nuevoNombre,
            inicio: nuevaHoraInicio,
            fin: nuevaHoraFin,
            cajas: periodo.cajas
          };
        });
      }
    
}
