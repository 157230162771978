import { Component, Input, OnInit } from '@angular/core';
import * as chartS from 'src/app/components/shared/data/charts'

@Component({
  selector: 'app-progress-bar-chart',
  templateUrl: './progress-bar-chart.component.html',
  styleUrls: ['./progress-bar-chart.component.scss']
})
export class ProgressBarChartComponent implements OnInit {
  @Input() data: any;
  @Input() carrier: any;

  chartData:any;

  colorsMapping:any = {
    'paquetexpress': { color:'#223e88'},
    'dhl': { color:'#ffcc01'},
    'afimex': { color:'#9108b2'},
    'estafeta': { color:'#c00d0d'},
    'estafeta mexico': { color: '#c00d0d' },
    'est': { color: '#c00d0d' },
    'fedex': { color:'#ff6200'},
    '99minutos': { color:'#85c440'},
  };

  constructor() { }

  ngOnInit(): void {
    // this.chartData = this.data;
    this.chartData = this.barChartProgress(this.data);
  }

  barChartProgress = (data:any) => {
    return (
      {
        chart: {
          height: 70,
          type: "bar",
          stacked: true,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "15%",
            colors: {
                backgroundBarColors: ["#bbb"],
                backgroundBarOpacity: 0.2,
            },
          },
        },
        colors: [this.getColorForCarrier(this.carrier)],
        stroke: {
          width: 0,
        },
        fill: {
          colors: [this.getColorForCarrier(this.carrier)],
          // type: "gradient",
          // gradient: {
          //   colors: [this.getColorForCarrier(this.carrier)],
          // },
        },
        series: [
          {
            data: [data],
          },
        ],
        tooltip: {
          enabled: false,
        },
        xaxis: {
          categories: ["Packed"],
        },
        yaxis: {
          max: 100,
          min: 0,
        },
        responsive: [
          {
            breakpoint: 767,
            options: {
              title: {
                style: {
                  fontSize: "16px",
                },
              },
            },
          },
        ]
      }
    )
  }

  getColorForCarrier(carrier: string): string {
    carrier = carrier.toLowerCase();
    for (let key in this.colorsMapping) {
      if (carrier.includes(key)) {
        return this.colorsMapping[key].color;
      }
    }
    return '#000000';
  }


}
