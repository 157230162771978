<div class="modal-header">
    <h5 class="pt-4 ps-4">Editar Registro</h5>
    <button type="button" class="btn-close me-4" mat-dialog-close></button>
</div>
<mat-dialog-content class="mat-typography">
    <div class="modal-body">
        <span>Captura la siguiente información:</span>
        <form autocomplete="off" [formGroup]="formSh" (ngSubmit)="ruSure()" class="mt-3" id="shipmentForm">
            <small>Campo obligatorio *</small>
            <div class="row mt-2">
                <div class="col-sm-6">
                    <mat-form-field class="w-100" color="accent">
                        <mat-label>Nombre del destinatario</mat-label>
                        <input matInput formControlName="name">
                    </mat-form-field>
                    <mat-form-field class="w-100" color="accent">
                        <mat-label>Correo</mat-label>
                        <input matInput formControlName="email">
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="w-100" color="accent">
                        <mat-label>Teléfono</mat-label>
                        <input matInput formControlName="phone">
                    </mat-form-field>
                    <mat-form-field class="w-100" color="accent">
                        <mat-label>Calle</mat-label>
                        <input matInput formControlName="street">
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="w-100" color="accent">
                        <mat-label>Número</mat-label>
                        <input matInput formControlName="number">
                    </mat-form-field>
                    <mat-form-field class="w-100" color="accent">
                        <mat-label>Ciudad</mat-label>
                        <input matInput formControlName="city">
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="w-100" color="accent">
                        <mat-label>Colonia</mat-label>
                        <input matInput formControlName="suburb">
                    </mat-form-field>
                    <mat-form-field class="w-100" color="accent">
                        <mat-label>Estado</mat-label>
                        <input matInput formControlName="state">
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    
                    <mat-form-field class="w-100" color="accent">
                        <mat-label>Código Postal</mat-label>
                        <input matInput formControlName="postalCode" type="number">
                    </mat-form-field>
                    <mat-form-field class="w-100" color="accent">
                        <mat-label>Provider</mat-label>
                        <input matInput formControlName="providers">
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="w-100" color="accent">
                        <mat-label>Referencia</mat-label>
                        <input matInput formControlName="reference">
                    </mat-form-field>
                    <mat-form-field class="w-100" color="accent">
                        <mat-label>Carrier</mat-label>
                        <input matInput formControlName="carriers">
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="w-100" color="accent">
                        <mat-label>Service</mat-label>
                        <input matInput formControlName="service">
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="w-100" color="accent">
                        <mat-label>Contenido</mat-label>
                        <input matInput formControlName="content">
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="w-100" color="accent">
                        <mat-label>Peso</mat-label>
                        <input matInput formControlName="weight" type="number">
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="w-100" color="accent">
                        <mat-label>Largo</mat-label>
                        <input matInput formControlName="length" type="number">
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="w-100" color="accent">
                        <mat-label>Ancho</mat-label>
                        <input matInput formControlName="width" type="number">
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="w-100" color="accent">
                        <mat-label>Alto</mat-label>
                        <input matInput formControlName="height" type="number">
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button class="bg-success text-white" form="shipmentForm" type="submit">Guardar <mat-icon>save</mat-icon></button>
</mat-dialog-actions>