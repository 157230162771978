import { Component, Input  } from '@angular/core';

interface Periodo {
  nombre: string;
  inicio: string;
  fin: string;
  cajas: number;
}

@Component({
  selector: 'app-progress-bar-periodos',
  templateUrl: './progress-bar-periodos.component.html',
  styleUrls: ['./progress-bar-periodos.component.scss']
})
export class ProgressBarPeriodosComponent {
  @Input() periodos: Periodo[] = [];
  @Input() progress: number = 0;
  @Input() cajas_encintadas: number = 0;
  @Input() total_cajas: number = 0;

  cajasAcumuladas: number = 0;

  getCajasAcumuladas(index: number): number {
    return this.periodos.slice(0, index + 1).reduce((sum, periodo) => sum + periodo.cajas, 0);
  }
}
