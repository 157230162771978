<div class="container-fluid">
    <!--Periodos-->
    <div class="row text-center divPeriodos mb-4 pb-3">
      <div class="col" *ngFor="let periodo of periodos; let i = index" [class.lastP]="i < periodos.length - 1">
            <div class="d-flex justify-content-center align-items-center">
                <mat-icon class="icono-azul me-1" [hidden]="cajas_encintadas >= getCajasAcumuladas(i)">hourglass_top</mat-icon>
                <mat-icon class="icono-azul me-1" [hidden]="cajas_encintadas < getCajasAcumuladas(i)">hourglass_bottom</mat-icon>
                <label class="fw-bold"> {{ periodo.nombre }}</label>
            </div>
            <div class="mt-1">
                <label>{{ periodo.inicio }} a {{ periodo.fin }}</label>
            </div>
        
      </div>
    </div>
    <!--Barra de progreso-->
    <div class="progress mt-3">
        <div class="progress2" [ngStyle]="{'width': progress + '%' }"></div>
        <div *ngFor="let periodo of periodos; let i = index" class="progress-segment" [ngStyle]="{'left': (i + 0.5) * (100 / periodos.length) + '%' }"> <!-- left para colocar medalla dinamicamente -->
            <div class="medal">
                <mat-icon 
                    [class.icono-plata]="i > 0 && i < periodos.length - 1 && cajas_encintadas >= getCajasAcumuladas(i)" 
                    [class.icono-bronce]="i == 0 && periodos.length > 1 && cajas_encintadas >= getCajasAcumuladas(i)" 
                    [class.icono-dorado]="i == periodos.length - 1 && cajas_encintadas >= getCajasAcumuladas(i)" 
                    [class.icono-azul2]="cajas_encintadas < getCajasAcumuladas(i)">
                    workspace_premium
                </mat-icon>
            </div>
        </div> 
    </div>
    <!--Cantidad de cajas-->
    <div class="row text-center mt-4">
      <div class="col" *ngFor="let periodo of periodos">
        <div class="d-flex justify-content-center align-items-center">
            <mat-icon class="icono-cafe me-1">inventory_2</mat-icon>
            <label class="fw-bold">{{ periodo.cajas }} cajas</label>
        </div>
      </div>
    </div>
</div>
