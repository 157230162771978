import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularMaterialModule } from './angular-material.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { LayoutComponent } from './components/shared/layout/layout.component';
import { SidebarComponent } from './components/shared/sidebar/sidebar.component';
import { LoginComponent } from './components/login/login.component';
import { UsersComponent } from './components/users/users.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { UsersAddComponent } from './components/shared/dialogs/users-add/users-add.component';
import { UsersEditComponent } from './components/shared/dialogs/users-edit/users-edit.component';
import { UsersEditPasswordComponent } from './components/shared/dialogs/users-edit-password/users-edit-password.component';
import { UsersEditPasswordFirsttimeComponent } from './components/shared/dialogs/users-edit-password-firsttime/users-edit-password-firsttime.component';
import { CampaignComponent } from './components/campaign/campaign.component';
import { CampaignsAddComponent } from './components/shared/dialogs/campaigns-add/campaigns-add.component';
import { CampaignsEditComponent } from './components/shared/dialogs/campaigns-edit/campaigns-edit.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { GoogleMapsModule } from '@angular/google-maps';
import { MaterialsComponent } from './components/distribution/materials/materials.component';
import { DistributionComponent } from './components/distribution/distribution.component';
import { DistributionUploadFileComponent } from './components/shared/dialogs/distribution-upload-file/distribution-upload-file.component';
import { NgxFileDragDropModule } from 'ngx-file-drag-drop';
import { DistributionUploadGuidesFileComponent } from './components/shared/dialogs/distribution-upload-guides-file/distribution-upload-guides-file.component';
import { DashboardPnpComponent } from './components/dashboard-pnp/dashboard-pnp.component';
import { DashboardPnpChartsComponent } from './components/shared/dialogs/dashboard-pnp-charts/dashboard-pnp-charts.component';
import { DashboardPnpMatComponent } from './components/dashboard-pnp-mat/dashboard-pnp-mat.component';
import { DashboardPnpBoxComponent } from './components/dashboard-pnp-box/dashboard-pnp-box.component';
import { DistributionUploadRacksFileComponent } from './components/shared/dialogs/distribution-upload-racks-file/distribution-upload-racks-file.component';
import { BoxMaterialsListComponent } from './components/shared/dialogs/box-materials-list/box-materials-list.component';
import { BgMaterialsListComponent } from './components/shared/dialogs/bg-materials-list/bg-materials-list.component';
import { RackMaterialsListComponent } from './components/shared/dialogs/rack-materials-list/rack-materials-list.component';
import { CampaignsSelectFileComponent } from './components/shared/dialogs/campaigns-select-file/campaigns-select-file.component';
import { MfAddComponent } from './components/shared/dialogs/mf-add/mf-add.component';
import { MfDocComponent } from './components/shared/dialogs/mf-doc/mf-doc.component';
import { CloseBoxesComponent } from './components/shared/dialogs/close-boxes/close-boxes.component';
import { DistributionUploadShipmentsFileComponent } from './components/shared/dialogs/distribution-upload-shipments-file/distribution-upload-shipments-file.component';
import { DistributionEditShipmentDataComponent } from './components/shared/dialogs/distribution-edit-shipment-data/distribution-edit-shipment-data.component';
import { DistributionMaterialsUploadImgsComponent } from './components/shared/dialogs/distribution-materials-upload-imgs/distribution-materials-upload-imgs.component';
import { DistributionMaterialsImgSlidersComponent } from './components/shared/dialogs/distribution-materials-img-sliders/distribution-materials-img-sliders.component';
import { DistributionUploadOrderedGuidesComponent } from './components/shared/dialogs/distribution-upload-ordered-guides/distribution-upload-ordered-guides.component';
import { TapingComponent } from './components/taping/taping.component';
import { ProgressBarChartComponent } from './components/shared/charts/progress-bar-chart/progress-bar-chart.component';
import { KeyeventComponent } from './components/shared/snackbars/keyevent/keyevent.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    LayoutComponent,
    SidebarComponent,
    LoginComponent,
    UsersComponent,
    DashboardComponent,
    UsersAddComponent,
    UsersEditComponent,
    UsersEditPasswordComponent,
    UsersEditPasswordFirsttimeComponent,
    CampaignComponent,
    CampaignsAddComponent,
    CampaignsEditComponent,
    CampaignsSelectFileComponent,
    MaterialsComponent,
    DistributionComponent,
    DistributionUploadFileComponent,
    DistributionUploadGuidesFileComponent,
    DashboardPnpComponent,
    DashboardPnpChartsComponent,
    DashboardPnpMatComponent,
    DashboardPnpBoxComponent,
    DistributionUploadRacksFileComponent,
    BoxMaterialsListComponent,
    BgMaterialsListComponent,
    RackMaterialsListComponent,
    MfAddComponent,
    MfDocComponent,
    CloseBoxesComponent,
    DistributionUploadShipmentsFileComponent,
    DistributionEditShipmentDataComponent,
    DistributionMaterialsUploadImgsComponent,
    DistributionMaterialsImgSlidersComponent,
    DistributionUploadOrderedGuidesComponent,
    TapingComponent,
    ProgressBarChartComponent,
    KeyeventComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgApexchartsModule,
    GoogleMapsModule,
    NgxFileDragDropModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
