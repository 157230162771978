import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileValidators } from 'ngx-file-drag-drop';
import { DistributionsService } from 'src/app/services/distributions.service';
import { MainService } from 'src/app/services/main.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-distribution-upload-ordered-guides',
  templateUrl: './distribution-upload-ordered-guides.component.html',
  styleUrls: ['./distribution-upload-ordered-guides.component.scss']
})
export class DistributionUploadOrderedGuidesComponent {
  bodyForm : FormData = new FormData(); 

  form!: FormGroup;
  fileControl = new FormControl([],[FileValidators.uniqueFileNames, FileValidators.maxFileCount(1)]);
  campaign : any;
  validated: boolean = false;
  isClientUsr: any;
  responsedata : any;
  res_errors: any;

  status: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private distService : DistributionsService,
    private main : MainService,
    private fb : FormBuilder,
    private dialogRef: MatDialogRef<DistributionUploadOrderedGuidesComponent>,
  ) { 
    this.campaign = this.data.campaign;
  }

  ngOnInit(): void {
    this.checkStatus(false);
    this. preloadFormData();
    this.loadForm();
  }

  preloadFormData(){
    this.bodyForm.append('id_campana', this.campaign.id);
  }
  
  loadForm() {
    this.form = this.fb.group({
      blob : [null, {validators:[Validators.required]}]
    });
  }
  
  onValueChange(files : any)
  { 
    this.res_errors = null;
    if(files.length >= 2)
    {      
      files.splice(1, 1);
      Swal.fire({
        icon: 'warning',
        title: 'Error',
        html: 'Sólo puede adjuntarse un documento'
      });
    }

    for (const item of files) {
      if(!item.name.endsWith('.xls')&&!item.name.endsWith('.xlsx'))
      {
        const index = files.indexOf(item);
  
        Swal.fire({
          icon: 'warning',
          title: 'Error',
          html: 'Solo se permiten documentos en formato Excel'
        });
    
        if (index >= 0) {
          files.splice(index, 1);
        }
      }
      else {
        this.form.value.blob = files;
      }
    }
  }

  checkStatus = (download: boolean) => {
    this.distService.getGuidesStatus(this.campaign.id).subscribe(
      (result:any)=>{
        if(result.message == 'Success' && result.data.status != null){
          this.status = result.data.status;
          if(result.data.status == 0){
            const tout = setTimeout(() => {
              this.checkStatus(download);
            }, 30000);
          }
          else{
            if(download && result.data.status == 1){
              // Obtener la URL del archivo zip desde la respuesta
              let url = result.data.file_guias;
              Swal.fire({
                icon: 'success',
                title: 'Archivo cargado',
                text: 'Archivo cargado exitosamente'
              }).then(() => {
                this.dialogRef.close({ data: url });
              });
            }
            else{
              if(result.data.status == 2){
                Swal.fire({
                  icon: 'error',
                  title: 'Error al cargar archivo',
                  text: 'Ocurrió un error al cargar el archivo, por favor vuelve a intentarlo.'
                });
              }
            }
            this.status = result.data.status;
          }
        }
        else{
          this.status = 4;
        }
      }
    );
  };

  downloadFile(){
    this.distService.getGuidesStatus(this.campaign.id).subscribe(
      (result:any)=>{
        if(result.message == 'Success'
        && result.data != null){
          // Obtener la URL del archivo zip desde la respuesta
          let url = result.data.file_guias;
          // Abrir la URL del archivo en una nueva pestaña
          window.open(url, '_blank');
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Error al descargar',
            text: 'Ocurrió un error al descargar el archivo, por favor vuelve a intentarlo.'
          });
        }
      }
    );
  }
  
  uploadFile(){
    console.log(this.form.value);
    if(this.form.value.blob != null && this.form.value.blob?.length!=0){
        this.bodyForm.append("blob", this.form.value.blob[0] as File);
      
      this.status = 0;
      this.distService.uploadOrderedGuides(this.bodyForm).subscribe(
        (result:any)=>{
          if(result.message == 'Success'){
            this.checkStatus(true);
          }
          else {
            this.res_errors = result.errors;
            Swal.fire({
              icon: 'error',
              title: 'Error al guardar',
              html: result.message
            });
            this.status = 2;
          }
        },
        (err:any)=>{
          this.main.HideLoading();
          if(err.error.message == "Invalid file") {
            Swal.fire({
              icon: 'warning',
              title: 'Error',
              html: 'Solo se permiten documentos en formato Excel'
            });
            this.status = 2;
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Error al guardar',
              text: err.error.message
            });
            this.status = 2;
          }
        }
      )
      
  }else{
      Swal.fire({
        icon: 'warning',
        title: 'Error',
        html: 'El documento no ha sido adjuntado'
      });
      this.status = 2;
    }
  }
}
