import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DistributionsService } from 'src/app/services/distributions.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-periodos',
  templateUrl: './periodos.component.html',
  styleUrls: ['./periodos.component.scss']
})
export class PeriodosComponent {

  dsPeriodos!: MatTableDataSource<any>;
  colsPeriodos: string[] = ['hora_inicio', 'hora_fin', 'cajas', 'eliminar'];
  rowsPeriodos: any[] = [];

  constructor(
      public dialogRef: MatDialogRef<PeriodosComponent>, 
      @Inject(MAT_DIALOG_DATA) public data: any,
      private distService : DistributionsService,
      
  ){
        
  }

  ngOnInit(){
  
    this.distService.getPeriodosCampaign(this.data.idObjetivo).subscribe(
      (result : any) => {
        if(result.data) {
          result.data.map( (e:any)=> {
            e.hora_inicio = this.convertDateTimeToAMPM(e.hora_inicio);
            e.hora_fin = this.convertDateTimeToAMPM(e.hora_fin) 
          })
          this.rowsPeriodos = result.data
          this.dsPeriodos = new MatTableDataSource(this.rowsPeriodos);
        }
      },
      (err : any) => {
        console.log(err);
      }
    );
  }


  addPeriodo(){
    this.rowsPeriodos.push({
      hora_inicio: "",
      hora_fin: "",
      cajas: 0,
      eliminar: "",
      idPeriodo: 0
    });

    this.dsPeriodos = new MatTableDataSource(this.rowsPeriodos);

  }


  validaPeriodos(objetivo: number, campana: number){

    const camposInvalidosInicio = this.rowsPeriodos.some(row => !row.hora_inicio);
    const camposInvalidosFin  = this.rowsPeriodos.some(row => !row.hora_fin);
    const camposInvalidosCajas  = this.rowsPeriodos.some(row => !row.cajas);
    let sumCajas: number = 0;

    this.rowsPeriodos.map((e:any) => {
      sumCajas += e.cajas;
    })

    if(camposInvalidosInicio){
      Swal.fire({
        icon: 'warning',
        title: 'Campos incompletos',
        text: 'Por favor, selecciona una hora de inicio.',
        confirmButtonText: 'Ok',
      });
      return;
    }
    if(camposInvalidosFin){
      Swal.fire({
        icon: 'warning',
        title: 'Campos incompletos',
        text: 'Por favor, selecciona una hora de fin.',
        confirmButtonText: 'Ok',
      });
      return;
    }
    if(camposInvalidosCajas){
      Swal.fire({
        icon: 'warning',
        title: 'Campos incompletos',
        text: 'Por favor, ingresa un número de cajas.',
        confirmButtonText: 'Ok',
      });
      return;
    }

    if( sumCajas > this.data.cajas){
      Swal.fire({
        icon: 'warning',
        title: 'Atención',
        text: 'La sumatoria total de cajas a encintar es mayor al total del objetivo, por favor modifique el numero de cajas para continuar',
        confirmButtonText: 'Ok',
      });
      return;
    }

    this.guardarPeriodos(objetivo,campana);
  }

  guardarPeriodos(objetivo: number, campana: number){

    const data = {
      id_objetivo: objetivo,
      id_campaign: campana,
      rows: this.rowsPeriodos
    };

    this.mostrarLoading();
    this.distService.setPeriodosCampaign(data).subscribe(
        (response: any) => {
          Swal.close();
          if(response.message == "Success") {
            Swal.fire({
              icon: 'success',
              title: 'Guardado',
              text: ''          
            });

            response.data.map( (e:any)=> {
              e.hora_inicio = this.convertDateTimeToAMPM(e.hora_inicio);
              e.hora_fin = this.convertDateTimeToAMPM(e.hora_fin) 
            })

            this.rowsPeriodos = response.data;
            this.dsPeriodos = new MatTableDataSource(this.rowsPeriodos);
          }
        },
        (error: any) => {
          Swal.close();
          console.error('Error al guardar:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
          });
        }
    );
  }

  convertDateTimeToAMPM(time: any) {
    const [hours, minutes, seconds] = time.split(':');
  
    let period = 'AM';
    let formattedHours = parseInt(hours, 10);
  
    if (formattedHours >= 12) {
      period = 'PM';
      if (formattedHours > 12) {
        formattedHours -= 12;
      }
    }
  
    return `${formattedHours}:${minutes} ${period}`;
  }

  eliminarFila(row: any) {

    Swal.fire({
      title: "Atención",
      text: "¿Estas seguro que quieres eliminar este periodo?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, confirmar",
      cancelButtonText: "Cancelar"
    }).then((result) => {
        if (result.isConfirmed) {
          const nuevosDatos = this.dsPeriodos.data.filter((item) => item !== row);
          this.dsPeriodos.data = nuevosDatos;
          this.rowsPeriodos = nuevosDatos;

          if(row.idPeriodo > 0){

            this.distService.setDeletePeriodo(row).subscribe(
              (response: any) => {
                if(response.message == "Success") {
                  Swal.fire({
                    icon: 'success',
                    title: 'Guardado',
                    text: ''          
                  });
                }
              },
              (error: any) => {
                console.error('Error al guardar:', error);
                Swal.fire({
                  icon: 'error',
                  title: 'Error al guardar',
                  text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
                });
              }
            );

          }
        }
    });
    
  }

  mostrarLoading() {
      Swal.fire({
        title: 'Guardando...',
        text: 'Por favor, espera.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
  }

}
