import { Component, HostListener, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { DistributionsService } from 'src/app/services/distributions.service';
import Swal from 'sweetalert2';
import { KeyeventComponent } from '../shared/snackbars/keyevent/keyevent.component';

@Component({
  selector: 'app-taping',
  templateUrl: './taping.component.html',
  styleUrls: ['./taping.component.scss']
})
export class TapingComponent implements OnInit {
  boxScanned: string = '';
  boxData: any;
  campaignListPNP = new Array<any>();
  isLoading = true;
  active: boolean = true;
  logs:any;
  code:any = "";
  tapingCampaignList = new Array<any>();
  selectedCampaign:any;
  validCarriers: string[] = ['99m', '99minutos', '99minutosmexico', '99minutosmx','afimex','bluexpress','blux','blx','bsm','buho','cch','chilexpress','chlex','chx','correoschile','dhl','dhlm','dhlmexico','est','estafeta','estafetamexico','estafetamx','estafetas','ests','fdx','fdxm','fedex','fedexmexico','hc gr','hcg','jtex','jtexpress','jtexpressmx','minicon_dhl','minicon_est','minicon_fdx','minicon_pex','mx99','mxpt','mxrp','paquetexpress','redpack','segmailmx','sgm','skn', 'stark','starken','ups'];

  constructor(
    private distS: DistributionsService,
    private campaignS: CampaignsService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.getTapingCampaigns();
    // this.setCampaign(this.tapingCampaignList.find((e:any)=> e.active = 1))
    // console.log(this.tapingCampaignList.find((e:any)=> e.active = 1))
    this.getTapingLogs();
  }

  getTapingLogs(){
    this.distS.getTapingLogs().subscribe(
      (result: any) => {
        this.logs = result.data;
        this.isLoading = false;
      },
      (err: any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  getLastCampaign() {
    return localStorage.getItem('campaign')
  }

  setCampaign(campaign:any){
    this.isLoading = true;
    localStorage.setItem('campaign', JSON.stringify(campaign));
    this.selectedCampaign = campaign;
    this.getCampaignsListPNP(campaign.id);  
  }

  getTapingCampaigns(){
    this.isLoading = true;
    this.distS.getTapingCampaigns().subscribe(
      (result: any) => {
        this.tapingCampaignList = result.data;
        this.setCampaign(result.data.find((e:any)=> e.active = 1));
        // console.log(result)
      },
      (err: any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  getCampaignsListPNP(id: number){
    // this.isLoading = true;
    // this.active = act==1;
    this.campaignS.getActiveCampaign(id).subscribe(
      (result: any) => {
        this.campaignListPNP = result;
        this.isLoading = false;
      },
      (err: any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  @HostListener('window:keypress', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.openKeyEventSB(this.code);
      this.processScannedData(this.code);
      this.code="";
    } else {
      this.code += event.key;
    }
  }

  openKeyEventSB(typed: string) {
    this._snackBar.openFromComponent(KeyeventComponent, {
      duration: 2000,
      horizontalPosition: "start",
      data: {
        typed: typed
      }
    });
  }

  private processScannedData(data: string) {
    this.isLoading = true;
    this.boxScanned = data;
    let box = {
      id_box: this.boxScanned,
      taped: 1,
    }
    this.distS.tapeBox(box).subscribe(
      (result:any) => {
        if(result.message == "Success") {
          let timerInterval:any;
          Swal.fire({
            icon: 'success',
            title: "Guardado",
            html: "Caja encintada",
            timer: 1000,
            showConfirmButton:false,
            timerProgressBar: true,
            willClose: () => {
              clearInterval(timerInterval);
            }
          }).then(() => {
            let id = this.getLastCampaign();
            let campaign = JSON.parse(id!)
            this.getCampaignsListPNP(result.box.id_campana||campaign.id);
            this.getTapingLogs();
          });
          this.boxData = result.box;
          this.isLoading = false;
        }else if(result.message === "Miss quality"){
          let timerInterval:any;
          this.boxData = result.box;
          Swal.fire({
            icon: 'success',
            title: 'Guardado',
            html:'<p style="font-size:18px">Caja encintada</p><p style="font-size:15px;color:red">Nota: Esta caja no a pasado por la estación de calidad.</p>',
            timer: 2500,
            showConfirmButton:false,
            timerProgressBar: true,
            willClose: () => {
              clearInterval(timerInterval);
            }
          }).then(() => {
            let id = this.getLastCampaign();
            let campaign = JSON.parse(id!)
            this.getCampaignsListPNP(result.box.id_campana||campaign.id);
            this.getTapingLogs();
          });
          this.isLoading = false;
        }else if(result.message === "Previously taped"){
          let timerInterval:any;
          this.boxData = result.box;
          Swal.fire({
            icon: 'info',
            title: 'Caja encintada',
            html:'<p style="font-size:15px;color:blue">Nota: Esta caja fue previamente encintada.</p>',
            timer: 2000,
            showConfirmButton:false,
            timerProgressBar: true,
            willClose: () => {
              clearInterval(timerInterval);
            }
          }).then(() => {
            // this.getCampaignsListPNP(1);
            this.getTapingLogs();
          });
          this.isLoading = false;
        }
      },
      (err:any) => {
        if(err.error.message === "Not found"){
          let timerInterval:any;
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'No se encontro la caja, por favor vuelva a intentarlo.',
            timer: 2500,
            showConfirmButton:false,
            timerProgressBar: true,
            willClose: () => {
              clearInterval(timerInterval);
            }
          });
          this.getTapingLogs();
          this.boxData = null;
          this.isLoading = false;
        }
        else{
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelva a intentarlo.'
          });
          this.isLoading = false;
        }
      }
    );
  }

  imgFormat(value:string) {
    if(!value) return;
    return value = value.replace(/\s+/g, '').toLowerCase();
  }

  formatTime(dateString: string, hours: number): Date {
    const date = new Date(dateString);
    date.setHours(date.getHours() + hours);
    return date;
  }

  isImageAvailable(carrier: any): any {
    return this.validCarriers.includes(carrier);
  }

}
