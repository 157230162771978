import { Injectable } from '@angular/core';
import { MainService } from './main.service';
import { CampaignModel } from '../models/campaigns.model';
import * as chartData from '../components/shared/data/charts';
import { map } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class MonitoringService {

    constructor(
        private main: MainService
    ) { }


    getCampaingsMonitoringList(){
        return this.main.getRequest("/monitoring/campaigns").pipe(
          map((resp: any) => {
            let campaignList: Array<CampaignModel> = [];
            if(resp.message == "Success") {          
              resp.data.forEach((e:any) => {
                campaignList.push(e);
              });
            }     
            return campaignList;
          })
        );
    }


    getMonitoringData(id:number){
        return this.main.getRequest(`/monitoring/show/${ id.toString() }`).pipe(
          map((resp: any) => {
            console.log(resp.data);  
            return resp;
          })
        );
    }

}