import { Injectable } from '@angular/core';
import { MainService } from './main.service';
import { map } from 'rxjs';
import { BoxModelPNP, BranchModel, MaterialsModelPNP } from '../models/campaigns.model';

@Injectable({
  providedIn: 'root'
})
export class DistributionsService {

  constructor(
    private main: MainService
  ) { }

  uploadDist(form : FormData){
    return this.main.postRequestFormData(form, "/distribution/files/campaign");
  }

  uploadRackDist(form : FormData){
    return this.main.postRequestFormData(form, "/distribution/files/rack");
  }

  uploadGuides(form : FormData){
    return this.main.postRequestFormData(form, "/distribution/files/guides");
  }
  
  uploadOrderedGuides(form : FormData){
    return this.main.postRequestFormData(form, "/distribution/files/custom_guides/");
  }
  
  generateShipmentsFile(id_campana: number){
    return this.main.getRequest("/distribution/files/guides/" + id_campana );
  }

  uploadShipmentData(form : FormData){
    return this.main.postRequestFormData(form, "/distribution/files/shipmentdata");
  }

  getSucBgs(id: string) {
    return this.main.getRequest("/distribution/data/estafeta/" + id);
  }

  getFileSucBgs(id: string) {
    return this.main.getRequest("/distribution/files/estafeta/" + id);
  }

  getMaterials() {
    return this.main.getRequest("/distribution/materials/");
  }

  getCampBoxListPNP(id_camp: number){
    return this.main.getRequest("/distribution/dashboard/" + id_camp).pipe(
      map((resp: any) => {
        let boxListPNP: Array<BoxModelPNP> = [];

        if(resp.message == "Success") {          
          boxListPNP = resp.data;
        } 
        
        return boxListPNP;
      })
    );    
  }

  getCampMaterialsListPNP(id_camp: number){
    return this.main.getRequest("/distribution/dashboard/materials/" + id_camp).pipe(
      map((resp: any) => {
        let materialsListPNP: Array<MaterialsModelPNP> = [];

        if(resp.message == "Success") {          
          materialsListPNP = resp.data;
        } 
        
        return materialsListPNP;
      })
    );    
  }

  getBoxMaterialsListPNP(id_box: number){
    return this.main.getRequest("/distribution/dashboard/box/materials/" + id_box).pipe(
      map((resp: any) => {
        let materialsListPNP: Array<MaterialsModelPNP> = [];
        if(resp.message == "Success") {          
          materialsListPNP = resp.data;
        } 
        return materialsListPNP;
      })
    );    
  }

  getBgMaterialsListPNP(id_bg: number){
    return this.main.getRequest("/distribution/dashboard/bg/materials/" + id_bg).pipe(
      map((resp: any) => {
        let materialsListPNP: Array<MaterialsModelPNP> = [];
        if(resp.message == "Success") {          
          materialsListPNP = resp.data;
        } 
        return materialsListPNP;
      })
    );    
  }

  getRackMaterialsListPNP(id_rack: number){
    return this.main.getRequest("/distribution/dashboard/rack/materials/" + id_rack).pipe(
      map((resp: any) => {
        let materialsListPNP: Array<MaterialsModelPNP> = [];
        if(resp.message == "Success") {          
          materialsListPNP = resp.data;
        } 
        return materialsListPNP;
      })
    );    
  }
  
  changeMatStatus(id_campana: number, id: number, status: number, type: string){
    if(type == "mat")
      return this.main.postRequest({ id_campana, id_material: id, status }, "/distribution/material/status");
    else 
      return this.main.postRequest({ id_campana, id_bg: id, status }, "/distribution/bg/status");
  }

  getCarriers(id_campana: number) {
    return this.main.getRequest("/distribution/carriers/" + id_campana);
  }
  
  getCarriersLine(id_campana: number) {
    return this.main.getRequest("/distribution/carriers/line/" + id_campana);
  }

  saveMf(mf: any){
    return this.main.postRequest(mf, "/distribution/mf");
  }
  
  saveSignatureMf(form : FormData){
    return this.main.postRequestFormData(form, "/distribution/mf/sign");
  }

  saveDocMf(id_mf: number){
    return this.main.getRequest("/distribution/mf/doc/" + id_mf);
  }

  getMf(id_campana: number) {
    return this.main.getRequest("/distribution/mf/" + id_campana);
  }
  
  getDisShipmentData(id_campana: number) {
    return this.main.getRequest("/distribution/shipmentdata/" + id_campana);
  }
  
  editDistShData(data: any) {
    return this.main.postRequest(data,"/distribution/shipment/editData");
  }

  getListBoxMf(id_campana: number, paqueteria: string) {
    return this.main.postRequest({ id_campana, paqueteria },"/distribution/mf/boxlist");
  }

  getListBoxByCarrier(id_campana: number, paqueteria: string) {
    return this.main.postRequest({ id_campana, paqueteria },"/distribution/boxlist/carrier");
  }

  setCloseListBox(id_campana: number, paqueteria: string, box_list: any) {
    return this.main.postRequest({ id_campana, paqueteria, box_list },"/distribution/boxlist/close");
  }

  getLabels(id_campana: number, ids: any) {
    return this.main.postRequest({ id_campana, ids },"/distribution/shipment/getlabels");
  }

  uploadMatImgs(form : FormData){
    return this.main.postRequestFormData(form, "/distribution/files/materials");
  }

  tapeBox(data:any){
    return this.main.postRequestFormData(data, "/distribution/tapeBox");
  }

  getTapingLogs() {
    return this.main.getRequest("/distribution/getTapingLogs");
  }

  getTapingCampaigns() {
    return this.main.getRequest("/campaign/taping_campanas");
  }
  
}